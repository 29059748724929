import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation,useNavigate } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner,Container } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  putRequest,
  customValidator,
  postRequestForm,
} from "../../components/Common/Utils.js";
import Lightbox from "react-image-lightbox";
import FileUpload from "../../components/Common/FileUpload.js";

 import BusinessModal from "../../components/Modal/BannerModal";

export default function UpdateCategory() {
  let history = useNavigate();
  const location = useLocation();
  const state = location.state?.row;

  const [categoryData, setCategoryData] = useState({
    title: "",
    subTitle: "",
    startColor: "",
    endColor: "",
    selectedImage: "",
  });
  const [previewImage, setPreviewImage] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [openLightbox2, setOpenLightBox2] = React.useState(false);
  const [businessImage, setBusinessImage] = useState("");
  const [imageIcon, setImageIcon] = useState("");
  const [businessText, setBusinessText] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [startLoader, setStartLoader] = useState(false);
  const [model, setModel] = useState(false);
  const [businessId, setBusinessId] = useState("");
  const [bussinessData, setBussinessData] = useState([]);


  const { id } = useParams();

  // useEffect(() => {
  //   state.startColor = state.color.startColor;
  //   state.endColor = state.color.endColor;

  //   setCategoryData(state);
  //   setImageIcon(state?.image ? state?.image : "");
  // }, [state]);

  useEffect(() => {
    if (state) {
      // Safely extract values from state
      const startColor = state.color?.startColor || "";
      const endColor = state.color?.endColor || "";
      const banners = state.banners || [];
      const defaultBanner = banners[0] || {}; // Use the first banner as the default
      const defaultBannerImage = defaultBanner.items?.[0]?.image || "";
      const defaultBannerTitle = defaultBanner.title || "No Banner chosen";
  
      // Update the states
      setBusinessId(defaultBanner._id || ""); // Banner ID
      setBusinessText(defaultBannerTitle); // Banner title
      setBusinessImage(defaultBannerImage); // First item image in the banner
      setImageIcon(state?.image || "");
  
      // Update category data
      setCategoryData((prevData) => ({
        ...prevData,
        ...state,
        startColor,
        endColor,
      }));
    }
  }, [state]);
  
  
  

 
  

  const closeModal = () => {
    setModel(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };

  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };
  const showLightBox2 = (imageIcon) => {
    setImageIcon(imageIcon);
    setOpenLightBox2(true);
  };

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
      setImageIcon("");
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  const Updatecategory = async (e) => {
    e.preventDefault();
    const validator = customValidator(
      [
        {
          field: "title",
          errorMsg: "Title is required",
        },
        // {
        //   field: "subTitle",
        //   errorMsg: "Please enter subtitle",
        // },
        {
          field: "startColor",
          errorMsg: "Please enter start color",
        },
        {
          field: "endColor",
          errorMsg: "Please enter end color",
        },
      ],
      categoryData
    );
    if (!validator.success) {
      toast.error(validator.message);
      return;
    }
    
    setLoading(true);

    const formData = new FormData();
    const selectedImageForm = new FormData();
    let selectedImageRes = null;
    
    if(selectedImage){
    selectedImageForm.append("image", selectedImage);
    setStartLoader(true);
    selectedImageRes = await postRequestForm(
      "comman/img/upload",
      selectedImageForm
    );
    setStartLoader(false);
    }

    formData.append("categoryId", id);
    formData.append("title", categoryData.title);
    formData.append("subTitle", categoryData.subTitle);
    formData.append("startColor", categoryData.startColor);
    formData.append("endColor", categoryData.endColor);
    formData.append("displayAllweek", categoryData.displayAllweek == "true" ? 1 : 0);
    formData.append("allowShuffle", categoryData.allowShuffle == "true" ? 1 : 0);
    formData.append("status", categoryData.status == "true" ? 1 : 0);
    if (selectedImageRes?.data?.url) {
      formData.append("image", selectedImageRes.data.url);
    } else {
      console.error("Image URL not found in the response.");
    }

    // fetch _id from bussinessData
  

    const payload = {
      categoryId: id,
      title: categoryData.title,
      subTitle: categoryData.subTitle,
      startColor: categoryData.startColor,
      endColor: categoryData.endColor,
      displayAllweek: categoryData.displayAllweek == "true" ? 1 : 0,
      allowShuffle: categoryData.allowShuffle == "true" ? 1 : 0,
      status: categoryData.status == "true" ? 1 : 0,
      image: selectedImageRes?.data?.url,     
      banners: [businessId],
    }

    const response = await putRequest("venue/category/update", payload);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history(-1);
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...categoryData };
    list[index] = value;
    setCategoryData(list);
  };

  const selectRow = (id, name, logo) => {
    setBusinessId(id?._id);
    setBusinessText(id?.title);
    setBusinessImage(id?.items[0]?.image);   

    //console.log("id", id);
    
    
    setBussinessData(id?.items);
    closeModal();
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">

<Container>

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Category Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Category Management</li>:{" "}
              <li className="breadcrumb-item">Update Category</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history(-1)}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>

      {/* <Row>
        <Col lg={12}>
          <div className="col-md-2">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.push(`/category`)}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
          <br />
          <br />
        </Col>
      </Row> */}

      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Update Category </h4>
                <Form onSubmit={Updatecategory}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              value={categoryData?.title}
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>


                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Sub Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("subTitle", e.target.value)
                              }
                              value={categoryData?.subTitle}
                              name="subTitle"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Banner
                        </label>

                        <div
                          className="col-sm-6"
                          onClick={() => toggleModel()}
                        >

                          {businessText ? (

                            <div class="input-group">
                              <button
                                class="btn"
                                style={{
                                  border: "1px solid #ced4da",
                                  background: "#5e18c9",
                                  color: "#ffff",
                                }}
                                type="button"
                                id="chooseVenueBtn"
                                onclick={() => toggleModel()}
                              >
                                Select Banner
                              </button>
                              <div
                                style={{
                                  border: "1px solid #ced4da",
                                  width: "16px",
                                }}
                              ></div>
                              <img
                                src={businessImage}
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  border: "1px solid #ced4da",
                                  borderRight: "none",
                                  borderLeft: "none",
                                }}
                              ></img>
                              <input
                                type="text"
                                style={{ borderLeft: "none" }}
                                class="form-control"
                                readonly
                                value={businessText}
                                id="selectedVenue"
                                placeholder="No Banner chosen"
                              />
                            </div>
                          ) : (

                            <div class="input-group">
                              <button
                                class="btn"
                                style={{
                                  border: "1px solid #ced4da",
                                  background: "#5e18c9",
                                  color: "#ffff",
                                }}
                                type="button"
                                id="chooseVenueBtn"
                                onclick={() => toggleModel()}
                              >
                                Select Banner
                              </button>
                              <input
                                type="text"
                                class="form-control"
                                readonly
                                id="selectedVenue"
                                placeholder="No Banner chosen"
                              />
                            </div>
                          )}
                        </div>
                      </div>




                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Start Color
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="color"
                              onChange={(e) =>
                                updateValue("startColor", e.target.value)
                              }
                              value={categoryData?.startColor}
                              name="startColor"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            End Color
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="color"
                              onChange={(e) =>
                                updateValue("endColor", e.target.value)
                              }
                              value={categoryData?.endColor}
                              name="endColor"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                        Display all week offers
                        </label>
                        <div className="col-sm-6">
                          <select
                            onChange={(e) =>
                              updateValue("displayAllweek", e.target.value)
                            }
                            className="form-select"
                          >
                            <option value="true" selected>
                              Yes
                            </option>
                            <option value="false">No</option>
                          </select>
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                        Allow shuffle
                        </label>
                        <div className="col-sm-6">
                          <select
                            onChange={(e) =>
                              updateValue("allowShuffle", e.target.value)
                            }
                            className="form-select"
                          >
                            <option value="true" selected>
                              Yes
                            </option>
                            <option value="false">No</option>
                          </select>
                        </div>
                      </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Image
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedImage(e.target.files[0])
                              }
                              name="image"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                            {previewImage && (
                              <img
                                src={previewImage}
                                onClick={() => showLightBox(previewImage)}
                                style={{ width: "100px", height: "100px" }}
                              />
                            )}

                            {imageIcon && (
                              <img
                                src={imageIcon}
                                onClick={() => showLightBox2(imageIcon)}
                                style={{ width: "100px", height: "100px" }}
                              />
                            )}
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Status
                          </label>
                          <div className="col-sm-6">
                            <select
                              value={categoryData?.status}
                              onChange={(e) =>
                                updateValue("status", e.target.value)
                              }
                              class="form-select"
                            >
                              <option> Select</option>
                              <option value="true">True</option>
                              <option value="false">False</option>
                            </select>
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-6">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      </Container> 


        </div>

      {openLightbox && (
        <Lightbox
          mainSrc={previewImage}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
      {openLightbox2 && (
        <Lightbox
          mainSrc={imageIcon}
          onCloseRequest={() => setOpenLightBox2(false)}
        />
      )}
      <FileUpload message="File Uploading" status={startLoader} />

      <BusinessModal show={model} closeModal={closeModal} selectRow={selectRow} />

    </React.Fragment>
  );
}
