import React, { useState, useEffect, useRef } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useHistory, useParams, useLocation,useNavigate } from "react-router-dom";
import {Container,Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";
import Lightbox from "react-image-lightbox";
import { ToastContainer, toast } from "react-toastify";
import {
  postRequest,
  putRequestForm,
  postRequestForm  
} from "../../components/Common/Utils.js";

import FileUpload from "../../components/Common/FileUpload";
// import IconPackModal from "components/Modal/IconPackModal.js";
import MultiSelect from "../../components/Multiselect/MultiselectCommon.js";
import DayTimePicker from "./DayTimePicker";
// import CancelPolicyModal from "../../components/Modal/CancelPolicyModal.js";
import LocationPicker from "react-location-picker";
const defaultPosition = {
  lat: 25.2048,
  lng: 55.2708,
};

// import BusinessModal from "components/Modal/BusinessModal";
export default function UpdateVenue() {
  
  const location = useLocation();
  const state = location.state?.row;
  console.log("state", state)
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [previewCover, setPreviewCover] = useState("");
  const [previewLogo, setPreviewLogo] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [openLightbox2, setOpenLightBox2] = React.useState(false);
  const [venueData, setVenueData] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(defaultPosition);
  const [loading, setLoading] = useState(false);
  const [verifyId, setVerifyId] = useState("");
  const [verifyList, setVerifyList] = useState([]);

  const [countries, setCountries] = useState([]);  
  const [regions, setRegions] = useState([]);

  const [policyModal, setPolicyModal] = useState(false);
  const [currentPolicyData, setCurrentPolicyData] = useState(null);
  const [policyRules, setPolicyRules] = useState([]);

  const [startLoader, setStartLoader] = useState(false);
  const [packages, setPackages] = useState([]);
  const [lockSubmit, setLockSubmit] = useState(false);
  const [selectedBadge, setSelectedBadge] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState([]);
  const [selectedMusic, setSelectedMusic] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState([]);
  const [selectedCuisine, setSelectedCuisine] = useState([]);

  const [themeList, setThemeList] = useState([]);
  const [badgeList, setBadgeList] = useState([]);
  const [musicList, setMusicList] = useState([]);
  const [featureList, setFeatureList] = useState([]);
  const [cuisineList, setCuisineList] = useState([]);
  const [model, setModel] = useState(false);
  const [businessId, setBusinessId] = useState("");
  const [businessText, setBusinessText] = useState("");
  const [businessImage, setBusinessImage] = useState("");

  const [defaultTheme, setDefaultTheme] = useState([]);
  const [defaultBadge, setDefaultBadge] = useState([]);
  const [defaultMusic, setDefaultMusic] = useState([]);
  const [defaultFeature, setDefaultFeature] = useState([]);
  const [defaultCuisine, setDefaultCuisine] = useState([]);
  const [completedCrop, setCompletedCrop] = useState(null);

  const [selectedLogo, setSelectedLogo] = useState("");

  const [completedCrop2, setCompletedCrop2] = useState(null);
  const [completedCrop3, setCompletedCrop3] = useState(null);
  const [cropImage, setCropImage] = useState(null);


  const [remoteRectangle, setRemoteRectangle] = useState(null);
  const [remoteSquare, setRemoteSquare] = useState(null);
  const [remoteStory, setRemoteStory] = useState(null);

  const [rectangleSelected, setrectangleSelected] = useState(0);
  const [squareSelected, setSquareSelected] = useState(0);
  const [storySelected, setStorySelected] = useState(0);

  const [crop, setCrop] = useState({
    unit: "px",
    x: 25,
    y: 25,
    width: 250,
    height: 250,
    aspect: 1,
    maxWidth: 250,
    maxHeight: 250,
    minHeight: 250,
    minWidth: 250,
  });

  const [cropRectangle, setCropRectangle] = useState({
    unit: "px",
    x: 25,
    y: 25,
    width: 540,
    height: 300,
    aspect: 1.8,
    maxWidth: 540,
    maxHeight: 300,
    minHeight: 300,
    minWidth: 540,
  });


  const [cropSquare, setCropSquare] = useState({
    unit: "px",
    x: 25,
    y: 25,
    width: 300,
    height: 300,
    aspect: 1,
    maxWidth: 300,
    maxHeight: 300,
    minHeight: 300,
    minWidth: 300,
  });


  const updateCropRectangle = (crop) => {
    const aspectRatio = 1.8; // Desired aspect ratio (1.8:1)
    
    // Calculate the new width and height based on the aspect ratio
    if (crop.width / crop.height !== aspectRatio) {
      crop.width = crop.height * aspectRatio;
      crop.height = crop.width / aspectRatio;
    }
  
    setCropRectangle(crop);
  };

  const updateCropSquare = (crop) => {
    const aspectRatio = 1; // Desired aspect ratio (1:1)

    // Calculate the new width and height based on the aspect ratio
    if (crop.width / crop.height !== aspectRatio) {
      crop.width = crop.height * aspectRatio;
      crop.height = crop.width / aspectRatio;
    }

    setCropSquare(crop);
  };

  const [selectedCover, setSelectedCover] = useState("");
  const blobUrlRef = useRef("");

  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [logoSelected, setLogoSelected] = useState(0);

  const [rectangleImage, setRectangleImage] = useState(null);
  const [squareImage, setSquareImage] = useState(null);
  const [storyImage, setStoryImage] = useState(null);

  const [rectangleImagePreview, setRectangleImagePreview] = useState(state?.rectangleImage || null);
  const [squareImagePreview, setSquareImagePreview] = useState(state?.squareImage || null);
  const [storyImagePreview, setStoryImagePreview] = useState(state?.storyImage || null);


  const [remoteLogo, setRemoteLogo] = useState(null);
  const [businessOptions, setBusinessOptions] = useState([
    { value: "", label: "None" },
  ]);
  const [businessData, setBusinessData] = useState([]);
  const [selectedBusinessData, setSelectedBusinessData] = useState([]);

  const [iconModal, setIconModal] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(0);

  const [timeOptions, setTimeOptions] = useState([
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
  ]);

  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];


  const updateTiming = (index, objName, value) => {
    const newTimeOptions = [...timeOptions];
    newTimeOptions[index][objName] = value;
    setTimeOptions(newTimeOptions);
  };

  const msUpdateMusic = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedMusic(ids);
  };

  const msUpdateFeature = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedFeature(ids);
  };

  const showLightBox = (previewCover) => {
    setPreviewCover(previewCover);
    setOpenLightBox(true);
  };
  const showLightBox2 = (previewLogo) => {
    setPreviewLogo(previewLogo);
    setOpenLightBox2(true);
  };


  let history = useNavigate();
  const { id } = useParams();

  function capitalizeFirstLetter(str) {
    if (typeof str !== "string" || str.length === 0) {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  useEffect(() => {
    const locationArray = state?.location?.coordinates;    
    state.latitude = locationArray[1];
    state.longitude = locationArray[0];
    setCurrentLocation({
      lat: parseFloat(state.latitude),
      lng: parseFloat(state.longitude),
    });
    setVenueData(state);
    setBusinessId(state?.business?._id)
    setBusinessText(state?.business?.name)
    setBusinessImage(state?.business?.logo)
    setPreviewCover(state?.cover ? state?.cover : "");
    setPreviewLogo(state?.logo ? state?.logo : "");
    // setRectangleImagePreview(state?.rectangleImage ? state?.rectangleImage : "");
    // setSquareImagePreview(state?.squareImage ? state?.squareImage : "");
    // setStoryImagePreview(state?.storyImage ? state?.storyImage : "");

    if (state?.promo?.length) {
      const features = state?.promo?.map((item) => {
        return {
          image: item.image,
          title: item.title,
          discountTag: item.discountTag,
          saved: true,
        };
      });
      setPackages([...features]);
    }

    if (state?.timings && state?.timings.length > 0) {
      const newTimeOptions = [...timeOptions];
      state?.timings.map((item, index) => {
        const daysIndex = days.indexOf(capitalizeFirstLetter(item.day));
        newTimeOptions[daysIndex].checked = true;
        newTimeOptions[daysIndex].open = item.openingTime;
        newTimeOptions[daysIndex].close = item.closingTime;
      });
      setTimeOptions(newTimeOptions);
    }
    setSelectedBadge(state?.badges ? state?.badges : []);
    setSelectedTheme(state?.themes ? state?.themes : []);
    setSelectedMusic(state?.activities ? state?.activities : []);
    setSelectedFeature(state?.features ? state?.features : []);
    setSelectedCuisine(state?.cuisines ? state?.cuisines : []);

  }, [state]);

  useEffect(() => {
    fetchActivity();
  }, [selectedMusic]);

  useEffect(() => {
    fetchFeature();
  }, [selectedFeature]);  

  useEffect(() => {
    if (!selectedLogo) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewLogo(reader.result);
    };
    reader.readAsDataURL(selectedLogo);
  }, [selectedLogo]);

  const handleLogoChange = (e) => {
    setSelectedLogo(e.target.files[0]);
    setPreviewLogo(URL.createObjectURL(e.target.files[0]));
  };

  const handleCropComplete = (crop) => {
    setCompletedCrop(crop);
  };  

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );

        setLogoSelected(logoSelected + 1);
      }
    },
    100,
    [completedCrop]
  );

  useEffect(() => {
    if (!previewCanvasRef.current) {
      return;
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }
      blobUrlRef.current = URL.createObjectURL(blob);

      const file = new File([blob], venueData.name + "-logo.jpg", {
        type: "image/jpeg",
      });
      setRemoteLogo(file);
      
    });
  }, [logoSelected]);

  const handleLocationChange = ({ position, address, places }) => {
    const lng = position.lng;
    const lat = position.lat;    
    const venueDt = { ...venueData, latitude: lat, longitude: lng };
    setVenueData(venueDt);
  };
  const fetchActivity = async () => {
    const param = { page: 1, limit: 100000 };
    const music = await postRequest("activity/list/all", param);
    if (music.status == 1) {
      const options = music.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setMusicList(options);

      console.log("selectedMusic",selectedMusic)

      if (selectedMusic.length > 0) {
        const defaultItems = options.filter((item) => {
          console.log("test-2",selectedMusic, item)
          return selectedMusic.includes(item.id);
        });
        setDefaultMusic(defaultItems);
      }
    }
  };
  const fetchFeature = async () => {
    const param = { page: 1, limit: 100000 };
    const feature = await postRequest("venue/feature/list", param);
    if (feature.status == 1) {
      const options = feature.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });

      setFeatureList(options);
      if (selectedFeature.length > 0) {
        const defaultItems = options.filter((item) => {
          //console.log("test-1",selectedFeature, item)
          return selectedFeature.includes(item.id?.toString());
        });        
        setDefaultFeature(defaultItems);
      }
    }
  };
  useEffect(() => {
    if (!selectedCover) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewCover(reader.result);
    };
    reader.readAsDataURL(selectedCover);
  }, [selectedCover]);

  const Updatevenue = async (e) => {
      e.preventDefault();

      if (!validateFormFields()) return; // Validates form fields before proceeding

      const formData = new FormData();
      setStartLoader(true);

      // Prepare image uploads concurrently
      try {
          const uploadPromises = [
              remoteLogo ? postRequestForm("comman/logo/upload", createFormData("image", remoteLogo)) : Promise.resolve({ data: { url: null } }),
              selectedCover ? postRequestForm("comman/img/upload", createFormData("image", selectedCover)) : Promise.resolve({ data: { url: null } }),
              remoteRectangle ? postRequestForm("comman/img/upload", createFormData("image", remoteRectangle)) : Promise.resolve({ data: { url: null } }),
              remoteSquare ? postRequestForm("comman/img/upload", createFormData("image", remoteSquare)) : Promise.resolve({ data: { url: null } }),
              remoteStory ? postRequestForm("comman/img/upload", createFormData("image", remoteStory)) : Promise.resolve({ data: { url: null } }),
          ];

          const [logoResponse, coverResponse, rectangleResponse, squareResponse, storyResponse] = await Promise.all(uploadPromises);

          appendFormData(formData, {
              venueId: id,
              businessId,
              is_verified: venueData.is_verified,              
              name: venueData.name.trim(),
              about: venueData.about,
              address: venueData.address,
              phone: formatPhoneNumber(),
              email: venueData.email,
              website: venueData.website,
              country: venueData.country || "United Arab Emirates",
              region: venueData.region || "Dubai",
              isAlcohol: venueData.isAlcohol || "true",
              isManagedBy7N: venueData.isManagedBy7N || "true",
              latitude: venueData.latitude,
              longitude: venueData.longitude,
              booking_url: venueData.booking_url || "",
              menu_url: venueData.menu_url || "",
              dress_code: venueData.dress_code || "",
              claimCode: venueData.claimCode || "",
              reservationFee : venueData.reservationFee || 0,
              isOrderConfirmationRequired: venueData.isOrderConfirmationRequired || false,
              discountText: venueData.discountText || "",            
              isAllowReview: venueData.isAllowReview,
              isAllowRatting: venueData.isAllowRatting,
              promo: JSON.stringify(packages),
              badges: selectedBadge,
              themes: selectedTheme,
              activities: selectedMusic,
              features: selectedFeature,
              cuisines: selectedCuisine,
              timeOptions: formatTimeOptions(),
              cancelPolicy: JSON.stringify(venueData.cancelPolicy)
          });

          if(venueData?.is_verified && venueData.verificationType) {
            formData.append("verificationType", venueData.verificationType);
          }

          // Append images if upload was successful
          if (logoResponse?.data?.url) {
              formData.append("logo", logoResponse.data.url);
          }
          if (coverResponse?.data?.url) {
              formData.append("cover", coverResponse.data.url);
          }
          if (rectangleResponse?.data?.url) {
              formData.append("rectangleImage", rectangleResponse.data.url);
          }
          if (squareResponse?.data?.url) {
              formData.append("squareImage", squareResponse.data.url);
          }
          if (storyResponse?.data?.url) {
              formData.append("storyImage", storyResponse.data.url);
          }
          
          

          const response = await putRequestForm("venue/update", formData);
          handleResponse(response);
      } catch (error) {
          console.error("Failed to upload images or update venue:", error);
          toast.error("Failed to process the request.");
      } finally {
          setStartLoader(false);
      }
  };

  function createFormData(key, file) {
    const formData = new FormData();
    formData.append(key, file);
    return formData;
}

  function handleResponse(response) {
    if (response.status === 1) {
        toast.success(response.message);
        setTimeout(() => {
            history(-1);
        }, 3000);
    } else {
        toast.error(response.message);
    }
}

  function formatPhoneNumber() {
    return venueData.phone.includes(venueData.country_code) ? venueData.phone : venueData.country_code ? `${venueData.country_code} ${venueData.phone}` : venueData.phone ;
  }
// Other auxiliary functions remain unchanged
  function validateFormFields() {
    // Validate business ID
    // if (!businessId) {
    //     toast.error("Please select a business.");
    //     return false;
    // }

    // Validate venue name
    if (!venueData.name.trim()) {
        toast.error("Please enter a venue name.");
        return false;
    }

    // Validate about section
    if (!venueData.about.trim()) {
        toast.error("Please enter details about the venue.");
        return false;
    }

    // Validate address
    if (!venueData.address.trim()) {
        toast.error("Please enter the venue address.");
        return false;
    }

    // Validate phone number
    if (!venueData.phone.trim()) {

        

        toast.error("Please enter a phone number.");
        return false;
    } else {

        // Normalize the phone number by removing spaces and dashes
        const normalizedPhone = venueData.phone.trim().replace(/[\s\-]/g, '');

        // Define a regex that matches UAE and KSA phone number formats
        // UAE numbers start with +971 followed by 9 digits
        // KSA numbers start with +966 followed by 9 digits
        // The regex also allows for numbers starting directly with a digit sequence typical for local use without country code
        const regexUAEKSA = /^(\+971|971|0)?[0-9]{9}$|^(\+966|966|0)?[0-9]{9}$/;

        //console.log("Normalized Phone", normalizedPhone);

        // if (!regexUAEKSA.test(normalizedPhone)) {
        //     toast.error("Please enter a valid phone number. Include the country code if applicable (e.g., +971 or 971 for UAE, +966 or 966 for KSA).");
        //     return false;
        // }
      
    }

    // Validate email if provided
    if (venueData.email && !/^\S+@\S+\.\S+$/.test(venueData.email)) {
        toast.error("Please enter a valid email address.");
        return false;
    }

    // Validate website URL if provided
    // if (venueData.website && !/^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/.test(venueData.website)) {
    //     toast.error("Please enter a valid website URL.");
    //     return false;
    // }

    // Validate geographical coordinates
    if (!venueData.latitude || !venueData.longitude) {
        toast.error("Please specify the venue's geographical coordinates.");
        return false;
    } else {
        if (isNaN(venueData.latitude) || isNaN(venueData.longitude)) {
            toast.error("Latitude and longitude must be valid numbers.");
            return false;
        }
    }

    // // Validate selected themes, if applicable
    // if (selectedTheme.length === 0) {
    //     toast.error("Please select at least one theme.");
    //     return false;
    // }

    // Validate selected music options, if applicable
    if (selectedMusic.length === 0) {
        toast.error("Please select at least one activity.");
        return false;
    }

    // Validate selected features, if applicable
    if (selectedFeature.length === 0) {
        toast.error("Please select at least one feature.");
        return false;
    }

    // Validate selected cuisines, if applicable
    // if (selectedCuisine.length === 0) {
    //     toast.error("Please select at least one cuisine.");
    //     return false;
    // }

    // Check if at least one time option is selected
    let atLeastOneTimeChecked = timeOptions.some(option => option.checked);
    if (!atLeastOneTimeChecked) {
        toast.error("Please provide opening times for at least one day.");
        return false;
    }

    // All validations passed
    return true;
  }
  function formatTimeOptions() {
    const formattedOptions = [];
    timeOptions.forEach((item, index) => {
        if (item.checked) {
            formattedOptions.push({day: days[index].toLowerCase(), open_time: item.open, close_time: item.close});
        }
    });
    return formattedOptions;
  }
  function appendFormData(formData, data) {
    Object.keys(data).forEach(key => {
      if (key === 'timeOptions') {
          data[key].forEach(option => {
              formData.append('days[]', option.day);
              formData.append('open_time[]', option.open_time);
              formData.append('close_time[]', option.close_time);
          });
      } else if (Array.isArray(data[key])) {
          data[key].forEach(item => formData.append(`${key}[]`, item));
      } else {
          formData.append(key, data[key]);
      }
  });
}  
  const updateValue = (index, value) => {
    const list = { ...venueData };
    list[index] = value;
    setVenueData(list);
    //console.log("venueDt", index, value)

    if (index == "business_id") {
      const Bdata = businessData.find((x) => x._id === list.business_id);
      setSelectedBusinessData(Bdata);
    }

    if(index == "country") {      
      // fetch country code

      const country = countries.find((x) => x.id === value);
      const code = country.code;
      const list = { ...venueData };
      list["country_code"] = code;
      list[index] = value;
      setVenueData(list);
    }

  };
  const updateLocation = (value, type = "lat") => {
    const location = currentLocation;

    let newLocation = {
      lat: location.lat,
      lng: location.lng,
    };

    if (type === "lat") {
      newLocation.lat = parseFloat(value);
    } else {
      newLocation.lng = parseFloat(value);
    }
    setCurrentLocation(newLocation);
    setVenueData({
      ...venueData,
      latitude: newLocation.lat,
      longitude: newLocation.lng,
    });
  };
  useEffect(() => {
    if (!previewCanvasRef.current) {
      return;
    }
    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }

      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }

      blobUrlRef.current = URL.createObjectURL(blob);
      const file = new File([blob], venueData.name + "-rectangle.jpg", {
        type: "image/jpeg",
      });
      setRemoteRectangle(file);
    });
  }, [rectangleSelected]);

  useEffect(() => {
    if (!previewCanvasRef.current) {
      return;
    }
    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }
      blobUrlRef.current = URL.createObjectURL(blob);
      const file = new File([blob], venueData.name + "-square.jpg", {
        type: "image/jpeg",
      });
      setRemoteSquare(file);
    });
  }, [squareSelected]);

  useEffect(() => {
    if (!previewCanvasRef.current) {
      return;
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }

      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }

      blobUrlRef.current = URL.createObjectURL(blob);
      const file = new File([blob], venueData.name + "-story.jpg", {
        type: "image/jpeg",
      });
      setRemoteStory(file);
    });

  }, [storySelected]);
 

  useDebounceEffect(
    async () => {
      if (
        completedCrop2?.width &&
        completedCrop2?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop2,
          scale,
          rotate
        );
        setrectangleSelected(rectangleSelected + 1);
      }
    },
    100,
    [completedCrop2]
  );

  useDebounceEffect(
    async () => {
      if (
        completedCrop3?.width &&
        completedCrop3?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop3,
          scale,
          rotate
        );
        setSquareSelected(squareSelected + 1);
      }
    },
    100,
    [completedCrop3]
  );

  useDebounceEffect(
    async () => {
      if (
        cropImage?.width &&
        cropImage?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          cropImage,
          scale,
          rotate
        );
        setStorySelected(storySelected + 1);
      }
    },
    100,
    [cropImage]
  );


  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">

 <Container>

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Venue Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Venue Management</li>:{" "}
              <li className="breadcrumb-item">Update Venue</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() =>  history(-1)}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Update Venue </h4>
                <Form onSubmit={Updatevenue}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">                       
                     
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Name
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("name", e.target.value)
                              }
                              value={venueData?.name}
                              name="name"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            About
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("about", e.target.value)
                              }
                              value={venueData?.about}
                              rows="4"
                              cols="50"
                              name="about"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Address
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("address", e.target.value)
                              }
                              value={venueData?.address}
                              rows="4"
                              cols="50"
                              name="address"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>                      

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Phone
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="tel"
                              onChange={(e) =>
                                updateValue("phone", e.target.value)
                              }
                              defaultValue={venueData?.phone}
                              name="phone"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Email
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="email"
                              onChange={(e) =>
                                updateValue("email", e.target.value)
                              }
                              value={venueData?.email}
                              name="email"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Website
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("website", e.target.value)
                              }
                              value={venueData?.website}
                              name="website"
                              className="form-control"
                            />
                          </div>
                        </div>
                    
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Allow Reviews
                          </label>
                          <div className="col-sm-6">
                            <select
                              defaultValue={
                                venueData?.isAllowReview ? "true" : "false"
                              }
                              onChange={(e) =>
                                updateValue("isAllowReview", e.target.value)
                              }
                              className="form-select"
                            >
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Allow Ratings
                          </label>
                          <div className="col-sm-6">
                            <select
                              defaultValue={
                                venueData?.isAllowRatting ? "true" : "false"
                              }
                              onChange={(e) =>
                                updateValue("isAllowRatting", e.target.value)
                              }
                              className="form-select"
                            >
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Logo
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={handleLogoChange}
                              name="logo"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />

                            {previewLogo && selectedLogo ? (
                              <ReactCrop
                                crop={crop}
                                src={previewLogo}
                                onChange={(newCrop) => setCrop(newCrop)}
                                onComplete={handleCropComplete}
                                style={{
                                  maxWidth: "720px",
                                  maxHeight: "600px",
                                }}
                              >
                                <img ref={imgRef} src={previewLogo} />
                              </ReactCrop>
                            ) : (
                              <img
                                style={{ width: "100px", height: "100px" }}
                                src={previewLogo}
                                onClick={() => showLightBox2(previewLogo)}
                              />
                            )}
                            {completedCrop && (
                              <div>
                                <canvas
                                  ref={previewCanvasRef}
                                  style={{
                                    border: "1px solid black",
                                    objectFit: "contain",
                                    width: completedCrop.width,
                                    height: completedCrop.height,
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Cover
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedCover(e.target.files[0])
                              }
                              name="cover"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                            {previewCover && (
                              <img
                                src={previewCover}
                                onClick={() => showLightBox(previewCover)}
                                style={{ width: "100px", height: "100px" }}
                              />
                            )}
                          </div>
                        </div>

                  

                 

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Activity
                          </label>
                          <div className="col-sm-6">
                            <MultiSelect
                              options={musicList}
                              onSelect={msUpdateMusic}
                              onRemove={msUpdateMusic}
                              selectedValues={defaultMusic}
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Feature
                          </label>
                          <div className="col-sm-6">
                            <MultiSelect
                              options={featureList}
                              onSelect={msUpdateFeature}
                              onRemove={msUpdateFeature}
                              selectedValues={defaultFeature}
                              placeholder="Search Feature"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Time
                          </label>
                          <div className="col-sm-6">
                            {days.map((day, index) => (
                              <DayTimePicker
                                updateTiming={updateTiming}
                                currentValue={timeOptions[index]}
                                index={index}
                                key={index}
                                day={day}
                              />
                            ))}
                          </div>
                        </div>

                   
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-email-input"
                            className="col-sm-3 col-form-label"
                          >
                            Select Location
                          </label>
                          <div className="col-sm-6">
                            <LocationPicker
                              containerElement={
                                <div style={{ height: "100%" }} />
                              }
                              mapElement={<div style={{ height: "600px" }} />}
                              defaultPosition={currentLocation}
                              onChange={handleLocationChange}
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-email-input"
                            className="col-sm-3 col-form-label"
                          >
                            Latitude
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number" // Change the type to "number"
                              step="any"
                              name="latitude"
                              min={0}
                              value={venueData?.latitude}
                              onChange={(e) =>
                                updateLocation(e.target.value, "lat")
                              }
                              className="form-control"
                              id="horizontal-email-input"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-email-input"
                            className="col-sm-3 col-form-label"
                          >
                            Longitude
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number" // Change the type to "number"
                              step="any"
                              name="longitude"
                              min={0}
                              value={venueData?.longitude}
                              onChange={(e) =>
                                updateLocation(e.target.value, "lng")
                              }
                              className="form-control"
                              id="horizontal-email-input"
                            />
                          </div>
                        </div>

                   

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                  disabled={lockSubmit}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      </Container> 


</div>      
      {openLightbox && (
        <Lightbox
          mainSrc={previewCover}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
      {openLightbox2 && (
        <Lightbox
          mainSrc={previewLogo}
          onCloseRequest={() => setOpenLightBox2(false)}
        />
      )}
      <FileUpload message="File Uploading" status={startLoader} />
    </React.Fragment>
  );
}
