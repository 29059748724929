import React, { useState, useEffect } from "react";
import { Row, Col, Card, Spinner, Button, CardBody,Container } from "reactstrap";
import { postRequest, putRequestForm, deleteRequest, RBButton, deleteConfirmationWords } from "../../components/Common/Utils";
import { useParams, Link,useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, makeStyles } from "@material-ui/core";
import LocationPicker from "react-location-picker";
import DayTimePicker from "./DayTimePicker";
 import TagSelection from "./TagSelection";
import { ToastContainer, toast } from "react-toastify";

 import VenueOfferListTable from "./VenueOfferTable";
// import VenueSpecialOfferListTable from "./VenueSpecialOfferList";
// import VenueHomeBlockListTable from "./VenueHomeblockList";
// import VenueClaimsListTable from "./VenueClaimsTable";

// import VenueReserveListTable from "./VenueReserveTable";
 import UpdateVenueDetail from "./UpdateVenueDetail";
// import VenueTableList from "./VenueTableList";
// import VenueNotificationListTable from "./VenueNotificationTable";
// import VenueReviewListTable from "./VenueReviewTable";
// import VenueFollowListTable from "./VenueFollowingTable";
// import VenueActiveStoryListTable from "./VenueActiveStoryTable";
// import VenueStoryListTable from "./VenueStoryTable";
 import OrderManagementListTable from "./OrderManagmentListTable";

import "./CustomCard.css";

const defaultPosition = {
  lat: 25.2048,
  lng: 55.2708,
};

const useStyles = makeStyles({
  tableHeader: {
    color: "#ffffff",
    backgroundColor: "#e22a2a",
    fontWeight: "bold",
  },
  descriptionText: {
    fontSize: "0.8rem",
  },
  deleteIcon: {
    color: "red",
  },
  roundImage: {
    borderRadius: "50%",
    width: "75px",
    height: "75px",
  },
  roundImageSm: {
    borderRadius: "50%",
  },
  gridList: {
    width: "100%",
  },
  icon: {
    color: "rgba(255, 55, 25, 0.54)",
  },
});

const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const allTabs = [
  "Update Venue",  "Location", "Timings", "Features",  "Offers",   "Orders",
  
];

export default function VenueDetails({ venueId, venueName = "" }) {
  const history = useNavigate();
  const [venueData, setData] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(defaultPosition);
  const [loader, setLoader] = useState(true);
  const [image, setImage] = useState("");
  const [openLightbox, setOpenLightBox] = useState(false);
  const [selectedMusic, setSelectedMusic] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState([]);
  const [selectedCuisine, setSelectedCuisine] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);
  const [textReply, setTextReply] = useState("");
  const location = useLocation();
  const classes = useStyles();
  const { id } = useParams();

  const timeOptionsInitial = Array(7).fill({ checked: false, open: "00:00", close: "00:00" });
  const [timeOptions, setTimeOptions] = useState(timeOptionsInitial);

  const getActiveQ = () => {
    const search = location.search;
    return new URLSearchParams(search).get("active");
  };

  const [activeQ, setActiveQ] = useState(getActiveQ() || "Update Venue");
  const [visibleTabs, setVisibleTabs] = useState(allTabs.slice(0, 9));
  const [dropdownTabs, setDropdownTabs] = useState(allTabs.slice(9));

  const VenueFetchDetail = async () => {
    setLoader(true);
    postRequest(`venue/detail/admin`, { venueId: `${id}` }).then((data) => {
      const venueData = data.data;
      setData(venueData);
      setCurrentLocation({
        lat: parseFloat(venueData?.location?.coordinates[1]),
        lng: parseFloat(venueData?.location?.coordinates[0]),
      });

      if (venueData?.timings?.length > 0) {
        const newTimeOptions = [...timeOptions];
        venueData.timings.forEach((item) => {
          const daysIndex = days.indexOf(item.day.charAt(0).toUpperCase() + item.day.slice(1));
          newTimeOptions[daysIndex] = { checked: true, open: item.openingTime, close: item.closingTime };
        });
        setTimeOptions(newTimeOptions);
      }

      // setSelectedMusic(venueData.music || []);
      // setSelectedTheme(venueData.themes || []);
      // setSelectedCuisine(venueData.cuisines || []);
      setSelectedFeature(venueData.features || []);
      setLoader(false);
    });
  };

  useEffect(() => {
    VenueFetchDetail();
  }, []);

  const handleSelectionChange = (setSelectedFunc, url, venueId, itemId) => async (newSelectedItems) => {
    const oldItems = [...newSelectedItems];
    const item = newSelectedItems.find((newItem) => !oldItems.some((oldItem) => oldItem._id === newItem._id));
    if (item) {
      setSelectedFunc(newSelectedItems);
      await deleteRequest(url, { venueId, itemId: item._id });
    }
  };

  const updateTiming = (index, objName, value) => {
    const newTimeOptions = [...timeOptions];
    newTimeOptions[index][objName] = value;
    setTimeOptions(newTimeOptions);
  };

  const Updatevenue = async () => {
    const formData = new FormData();
    formData.append("venueId", id);
    timeOptions.forEach((item, index) => {
      if (item.checked) {
        formData.append(`days[]`, days[index].toLowerCase());
        formData.append(`open_time[]`, item.open);
        formData.append(`close_time[]`, item.close);
      }
    });
    const response = await putRequestForm("venue/update-by-field", formData);
    if (response.status === 1) {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  };

  const handlerdelete2 = (id, file) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        showRandomWordDialog(id, file);
      }
    });
  };

  const showRandomWordDialog = (id, file) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteGalleryFile(id, file);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteGalleryFile = async (id, file) => {
    const response = await deleteRequest(`venue/gallery/delete`, {
      venueId: id,
      file: file,
    });
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      VenueFetchDetail();
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString(undefined, { year: "numeric", month: "long", day: "numeric" });
  };

  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (review) => {
    setSelectedReview(review);
    setOpen(true);
  };

  const updateReply = async () => {
    const response = await putRequest("rating/reply/update", {
      ratingId: selectedReview._id,
      reply: textReply,
    });
    if (selectedReview.reply?.reply) {
      selectedReview.reply.reply = textReply;
    } else {
      selectedReview.reply = { reply: textReply };
    }
    setSelectedReview(selectedReview);
    setOpen(false);
    if (response.status === 1) {
      toast.success("Reply Updated");
    } else {
      toast.error(response.message);
    }
  };

  const {
    name,
    about,
    address,
    logo,
    cover,
    phone,
    email,
    website,
    booking_url,
    menu_url,
    dress_code,
    claimCode,
    discountText,
    createdAt,
    updatedAt,
  } = venueData;

  const [showDropdown, setShowDropdown] = useState(false);

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const handleTabClick = (tab) => {
    setActiveQ(tab);

    if (dropdownTabs.includes(tab)) {
      const newVisibleTabs = [...visibleTabs];
      const newDropdownTabs = [...dropdownTabs];
      const removedTab = newVisibleTabs.pop();
      newVisibleTabs.push(tab);

      const tabIndex = newDropdownTabs.indexOf(tab);
      newDropdownTabs.splice(tabIndex, 1);
      newDropdownTabs.push(removedTab);

      setVisibleTabs(newVisibleTabs);
      setDropdownTabs(newDropdownTabs);
    }

    setShowDropdown(false);
  };

  return (
    <>
      {/* <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Reply to Review</DialogTitle>
        <DialogContent>
          <DialogContentText>Please enter your reply to the review here.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Reply"
            type="text"
            fullWidth
            multiline
            defaultValue={selectedReview?.reply?.reply ? selectedReview?.reply?.reply : ""}
            onChange={(e) => {
              setTextReply(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={updateReply} color="primary">
            Reply
          </Button>
        </DialogActions>
      </Dialog> */}
      <ToastContainer />
      <div className="page-content">

<Container fluid> 
      <Row>
        <Col className="10">
          <div className="page-title-box">
            <h4>Venue Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Venue Management</li> :{" "}
              <li className="breadcrumb-item">Venue details</li>
            </ol>
          </div>
        </Col>
        <div className="col-md-2 text-end">
          <Button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={() => history(-1)}
          >
            <i className="fas fa-chevron-left"></i> Back
          </Button>
        </div>

        {loader ? (
          <CardBody style={{ height: "100px" }}>
            <Spinner
              color="info"
              style={{
                height: "4rem",
                width: "4rem",
                position: "absolute",
                left: "50%",
              }}
            >
              Loading...
            </Spinner>
          </CardBody>
        ) : (
          <>
            <Card className="custom-card">
              <div
                className="cover-image"
                style={{ backgroundImage: `url(${cover})` }}
                onClick={() => showLightBox(cover)}
              ></div>
              <div className="profile-image">
                <img
                  src={logo}
                  onClick={() => showLightBox(logo)}
                  alt="Image Not Available"
                />
              </div>
              <br />

              <div className="row my-2 py-2">
                <div
                  className="userinfo col-md-12"
                  style={{ paddingLeft: "30px", marginTop: "30px" }}
                >
                  <div className="fst">
                    <h3> {name} </h3>{" "}
                  </div>
                  <div className="row">
                    <div className="col-md-4 fst py-1">
                      <p>
                        Business Name: <span> {name}</span>
                      </p>
                    </div>
                    <div className="col-md-4 fst py-1">
                      <p>
                        Phone: <span> {phone}</span>
                      </p>
                    </div>
                    <div className="col-md-4 fst py-1">
                      <p>
                        Email: <span> {email}</span>
                      </p>
                    </div>
                    <div className="col-md-4 fst py-1">
                      <p>
                        Address: <span> {address}</span>
                      </p>
                    </div>
                    <div className="col-md-4 fst py-1">
                      <p>
                        Website:{" "}
                        <span>
                          <a
                            href={website}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Visit Website
                          </a>
                        </span>
                      </p>
                    </div>
                    {booking_url && (
                      <div className="col-md-4 fst py-1">
                        <p>
                          Booking URL:{" "}
                          <span>
                            <a
                              href={booking_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Book Now
                            </a>
                          </span>
                        </p>
                      </div>
                    )}
                    {menu_url && (
                      <div className="col-md-4 fst py-1">
                        <p>
                          Menu URL:{" "}
                          <span>
                            <a
                              href={menu_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Menu
                            </a>
                          </span>
                        </p>
                      </div>
                    )}

                   
                    
                    
                    <div className="col-md-4 fst py-1">
                      <p>
                        Created At: <span>{formatDate(createdAt)}</span>
                      </p>
                    </div>
                    <div className="col-md-4 fst py-1">
                      <p>
                        Updated At: <span>{formatDate(updatedAt)}</span>
                      </p>
                    </div>
                  </div>
                  <hr />

                  <div className="row">
                    <div className="col-md-12 fst py-1">
                      <p>
                        About: <span> {about}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
            <Col md="12" style={{ marginLeft: "inherit" }}>
              <div className="tab-container">
                <div className="tabs">
                  {visibleTabs.map(tab => (
                    <div
                      key={tab}
                      className={`tab ${activeQ === tab ? "active" : ""}`}
                      onClick={() => handleTabClick(tab)}
                    >
                      {tab}
                    </div>
                  ))}

                  {/* <div className="tab" onClick={handleDropdownToggle}>
                    More
                    <div className={`dropdown-menu ${showDropdown ? "show" : ""}`}>
                      {dropdownTabs.map(tab => (
                        <div
                          key={tab}
                          className="dropdown-item"
                          onClick={() => handleTabClick(tab)}
                        >
                          {tab}
                        </div>
                      ))}
                    </div>
                  </div> */}
                </div>
              </div>
            </Col>
            <Col md="1"></Col>      
            <Col md="10">
              <div className="tab-content clearfix">
              <div
                  className={`tab-pane ${activeQ === "Update Venue" ? "active" : ""} padded-tab`}
                  id="updateVenue"
                >
                  <div className="row">
                    <div className="map-container">
                      { venueData && (<UpdateVenueDetail venueDt={venueData}  />) }
                    </div>
                  </div>
                </div>
                <div
                  className={`tab-pane ${activeQ === "Location" ? "active" : ""} padded-tab`}
                  id="location"
                >
                  <div className="row">
                    <div className="map-container">
                      <LocationPicker
                        containerElement={<div style={{ height: "100%" }} />}
                        mapElement={<div style={{ height: "350px" }} />}
                        defaultPosition={currentLocation}
                      />
                    </div>
                  </div>
                </div>
                <div className={`tab-pane ${activeQ === "Timings" ? "active" : ""} padded-tab`} id="timings">
                  <div className="row mb-4">
                    <label className="col-sm-3 col-form-label">
                      Update Time
                    </label>
                    <div className="col-sm-9">
                      {days.map((day, index) => (
                        <DayTimePicker
                          updateTiming={updateTiming}
                          currentValue={timeOptions[index]}
                          index={index}
                          key={index}
                          day={day}
                        />
                      ))}
                    </div>

                    <div
                      className="col-sm-6 offset-6"
                      style={{ marginTop: "12px" }}
                    >
                      <Button
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                        onClick={Updatevenue}
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                </div>
                {/* <div className={`tab-pane ${activeQ === "Music" ? "active" : ""} padded-tab`} id="music">
                  <div className="row"></div>

                  <div className="row">
                    <div className="col-md-12 fst py-1">
                      {selectedMusic?.length && (
                        <TagSelection
                          itemList={venueData?.music_list}
                          selectedItemList={selectedMusic}
                          onSelectionChange={handleSelectionChange(
                            setSelectedMusic,
                            "venue/music/toggle",
                            id,
                            "musicId"
                          )}
                          addButton={{
                            path: "/createVenueAssignMusic",
                            state: {
                              row: {
                                venueId: venueData?._id,
                                venueName: venueData?.name,
                              },
                            },
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div> */}

                <div className={`tab-pane ${activeQ === "Features" ? "active" : ""} padded-tab`} id="features">
                 

                  <div className="row">
                    <div className="col-md-12 fst py-1">
                      {selectedFeature?.length && (
                        <TagSelection
                          itemList={venueData?.feature_list}
                          selectedItemList={selectedFeature}
                          onSelectionChange={handleSelectionChange(
                            setSelectedFeature,
                            "venue/feature/toggle",
                            id,
                            "featureId"
                          )}
                          addButton={{
                            path: "/createVenueAssignFeature",
                            state: {
                              row: {
                                venueId: venueData?._id,
                                venueName: venueData?.name,
                              },
                            },
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>

                {/* <div className={`tab-pane ${activeQ === "Themes" ? "active" : ""} padded-tab`} id="themes">
                  <div className="row">
                    <div className="col-md-12 fst py-1">
                      {selectedTheme?.length && (
                        <TagSelection
                          itemList={venueData?.theme_list}
                          selectedItemList={selectedTheme}
                          onSelectionChange={handleSelectionChange(
                            setSelectedTheme,
                            "venue/theme/toggle",
                            id,
                            "themeId"
                          )}
                          addButton={{
                            path: "/createVenueAssignTheme",
                            state: {
                              row: {
                                venueId: venueData?._id,
                                venueName: venueData?.name,
                              },
                            },
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div> */}

                {/* <div className={`tab-pane ${activeQ === "Cuisine" ? "active" : ""} padded-tab`} id="cuisine">
                  <div className="row">
                    <div className="col-md-12 fst py-1">
                      {selectedCuisine?.length && (
                        <TagSelection
                          itemList={venueData?.cuisine_list}
                          selectedItemList={selectedCuisine}
                          onSelectionChange={handleSelectionChange(
                            setSelectedCuisine,
                            "venue/cuisine/toggle",
                            id,
                            "cuisineId"
                          )}
                          addButton={{
                            path: "/createVenueAssignCuisine",
                            state: {
                              row: {
                                venueId: venueData?._id,
                                venueName: venueData?.name,
                              },
                            },
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div> */}

                <div className={`tab-pane ${activeQ === "Offers" ? "active" : ""} padded-tab`} id="offers">
                  {venueData.length !== 0 && (
                    <VenueOfferListTable
                      userData={venueData?.offers}
                      venueId={venueData?._id}
                      venueName={venueData?.name}
                      venueLogo={venueData?.logo}
                    />
                  )}
                </div>
{/* 
                <div className={`tab-pane ${activeQ === "Discounts" ? "active" : ""} padded-tab`} id="special-offers">
                  {venueData.length !== 0 && (
                    <VenueSpecialOfferListTable
                      userData={venueData?.offers_specials}
                      venueId={venueData?._id}
                      venueName={venueData?.name}
                      venueLogo={venueData?.logo}
                    />
                  )}
                </div> */}



                {/* <div className={`tab-pane ${activeQ === "Claims" ? "active" : ""} padded-tab`} id="claims">
                  {venueData.length !== 0 && (
                    <VenueClaimsListTable
                      userData={venueData?.claims}
                      venueId={venueData?._id}
                    />
                  )}
                </div> */}

                {/* <div className={`tab-pane ${activeQ === "Reservations" ? "active" : ""} padded-tab`} id="reservations">
                  {venueData.length !== 0 && (
                    <VenueReserveListTable
                      userData={venueData?.claims}
                      venueId={venueData?._id}
                    />
                  )}
                </div> */}
{/* 
                <div className={`tab-pane ${activeQ === "Venue Table" ? "active" : ""} padded-tab`} id="venuetable">
                  {venueData.length !== 0 && (
                    <VenueTableList
                      userData={venueData?.claims}
                      venueId={venueData?._id}
                    />
                  )}
                </div> */}


                {/* <div className={`tab-pane ${activeQ === "Gallery" ? "active" : ""} padded-tab`} id="gallery">
                  <Link
                    to={{
                      pathname: `/createGallery`,
                      state: {
                        row: {
                          venueId: venueData?._id,
                          venueName: venueData?.name,
                          venueLogo: venueData?.logo,
                        },
                      },
                      search: "?from=venue",
                    }}
                  >
                    <Button
                      type="button"
                      className="btn btn-info mx-2"
                      style={{ margin: "15px" }}
                    >
                      <i className="fas fa-plus"></i> Create
                    </Button>
                  </Link>
                  <br />
                  <Row>
                    {venueData?.galleries?.map((gallery, index) => (
                      <Col key={gallery} xs={12} sm={6} md={4} lg={3} xl={2}>
                        <Card>
                          <CardBody>
                            <img
                              src={gallery}
                              alt="gallery image"
                              className="mb-3"
                              style={{
                                height: "200px",
                                cursor: "pointer",
                                width: "100%",
                              }}
                              onClick={() => showLightBox(gallery)}
                            />
                            <div className="d-flex justify-content-between align-items-center">
                              <RBButton
                                color="danger"
                                onClick={() => handlerdelete2(id, gallery)}
                              >
                                <i className="fal fa-trash fs-5"></i>
                              </RBButton>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </div> */}

                {/* <div className={`tab-pane ${activeQ === "HomeBlocks" ? "active" : ""} padded-tab`} id="homeblocks">
                  {venueData.length !== 0 && (
                    <VenueHomeBlockListTable
                      userData={venueData?.offers_specials}
                      venueId={venueData?._id}
                      venueName={venueData?.name}
                    />
                  )}
                </div> */}

                {/* <div className={`tab-pane ${activeQ === "Notifications" ? "active" : ""} padded-tab`} id="notifications">
                  {venueData.length !== 0 && (
                    <VenueNotificationListTable
                      userData={venueData?.notifications}
                      venueId={venueData?._id}
                      venueName={venueData?.name}
                      venueLogo={venueData?.logo}
                    />
                  )}
                </div> */}

                {/* <div className={`tab-pane ${activeQ === "Reviews" ? "active" : ""} padded-tab`} id="reviews">
                  {venueData.length !== 0 && (
                    <VenueReviewListTable
                      userData={venueData?.reviews}
                      venueId={venueData?._id}
                    />
                  )}
                </div> */}

                {/* <div className={`tab-pane ${activeQ === "Following" ? "active" : ""} padded-tab`} id="following">
                  {venueData.length !== 0 && (
                    <VenueFollowListTable
                      userData={venueData?.follows}
                      venueId={venueData?._id}
                    />
                  )}
                </div> */}
                <div className={`tab-pane ${activeQ === "Orders" ? "active" : ""} padded-tab`} id="orders">
                  {venueData.length !== 0 && (
                    <OrderManagementListTable
                      userData={venueData?.follows || []}
                      venueId={venueData?._id}
                    />
                  )}
                </div>

                {/* <div className={`tab-pane ${activeQ === "ActiveStories" ? "active" : ""} padded-tab`} id="activeStories">
                  {venueData.length !== 0 && (
                    <VenueActiveStoryListTable
                      userData={venueData?.active_stories}
                      venueId={venueData?._id}
                      venueName={venueData?.name}
                      venueLogo={venueData?.logo}
                    />
                  )}
                </div> */}

                {/* <div className={`tab-pane ${activeQ === "Stories" ? "active" : ""} padded-tab`} id="stories">
                  {venueData.length !== 0 && (
                    <VenueStoryListTable
                      userData={venueData?.stories}
                      venueId={venueData?._id}
                      venueName={venueData?.name}
                      venueLogo={venueData?.logo}
                    />
                  )}
                </div> */}
              </div>
            </Col>
          </>
        )}

        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
      </Row>
      </Container>
      </div>
      <ToastContainer />{" "}
    </>
  );
}
