import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner,Container } from "reactstrap";

import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Lightbox from "react-image-lightbox";

import { putRequest } from "../../components/Common/Utils.js";
import ActivityModal from "../../components/Modal/ActivityModal.js";
import TeamModal from "../../components/Modal/TeamModal.js";
import VenueModal from "../../components/Modal/VenueModalOrg.js";
import LocationPicker from "react-location-picker";
import FileUpload from "../../components/Common/FileUpload.js";

const defaultPosition = {
  lat: 25.2048345,
  lng: 55.2708345,
};

export default function UpdateChallenge() {
  const location = useLocation();
  const state = location.state?.row;
  const [notificationData, setNotificationData] = useState({
    note: "",
    address: "",
    latitude: "",
    longitude: "",
    date: "",
    time: "",
    matchLevel: "",
    participantType: "",
    type: "",
    amount: 0,
    isLocationMarkBooked: ""   
  });
  const [loading, setLoading] = useState(false);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectedTarget, setSelectedTarget] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [userModel, setUserModel] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(defaultPosition);
  const [venueList, setVenueList] = useState([]);
  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");

  const [offerList, setOfferList] = useState([]);
  const [activityId, setActivityId] = useState("");
  const [activityText, setActivityText] = useState("");
  const [activityImage, setActivityImage] = useState("");

  const [categoryList, setCategoryList] = useState([]);
  const [teamId, setTeamId] = useState("");
  const [teamText, setTeamText] = useState("");
  const [teamImage, setTeamImage] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);

  const [model, setModel] = useState(false);
  const [teamModel, setTeamModel] = useState(false);
  const [activityModel, setActivityModel] = useState(false);
  const [startLoader, setStartLoader] = useState(false);
  let history = useNavigate();

  useEffect(() => {
    if (state) {
      
      setVenueId(state.venue?._id);
      setVenueText(state.venue?.name);
      setVenueImage(state.venue?.logo);
      setActivityId(state.activityData?._id);
      setActivityText(state.activityData?.title);
      setActivityImage(state.activityData?.icon);

      const locationArray = state?.location?.coordinates;    
        state.latitude = locationArray[1];
        state.longitude = locationArray[0];
        setCurrentLocation({
          lat: parseFloat(state.latitude),
          lng: parseFloat(state.longitude),
        });

        state.latitude = locationArray[1];
        state.longitude = locationArray[0];
        setNotificationData(state);
    }
  }, [state]);


  const updateNotificationData = async (e) => {
    e.preventDefault();

    if(!venueId){
      toast.error("Venue is required");
      return;
    }

    if(!activityId){
      toast.error("Activity is required");
      return;
    }


    if (!notificationData.note) {
      toast.error("Note is required");
      return;
    }

    if(!notificationData.address){
      toast.error("Address is required");
      return;
    }

    if(!notificationData.latitude){
      toast.error("Latitude is required");
      return;
    }

    if(!notificationData.longitude){
      toast.error("Longitude is required");
      return;
    }

    if(!notificationData.date){
      toast.error("Date is required");
      return;
    }

    if(!notificationData.time){
      toast.error("Time is required");
      return;
    }

    if(!notificationData.matchLevel){
      toast.error("Matchlevel is required");
      return;
    }


    if(!notificationData.participantType){
      toast.error("Participant Type is required");
      return;
    }

    if(!notificationData.type){
      toast.error("Type is required");
      return;
    }

    if(!notificationData.isLocationMarkBooked){
      toast.error("Is Location Booked is required");
      return;
    }
    
    const dataObj = {
      challengeId: state._id,
      ...notificationData,
      venueId: venueId,
      activityId: activityId     
    };
   

   
    const response = await putRequest("activity/challenge/update-admin", dataObj);
    setLoading(false);
    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history(-1);
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...notificationData };
    list[index] = value;
    setNotificationData(list);
  };

  const handleLocationChange = ({ position, address, places }) => {
    const lng = position.lng;
    const lat = position.lat;
    const venueDt = { ...notificationData, latitude: lat, longitude: lng };
    setNotificationData(venueDt);
  }; 

  const toggleModel = () => {
    setModel(!model);
  };
  const closeModal = () => {
    setModel(false);
  };

  const selectRow = (id, name, venue) => {   
    setVenueId(id);
    setVenueText(name);
    setVenueImage(venue?.logo);   
    closeModalVenue();
  };

  const closeModalVenue = () => {
    setModel(false);
  };

  const toggleActivityModel = () => {
    setActivityModel(!activityModel);
  };
  const closeActivityModal = () => {
    setActivityModel(false);
  };

  const selectActivityRow = (id, row) => {
     setActivityId(id);
    setActivityText(row.title);
     setActivityImage(row.icon);
    closeActivityModal();
  };

  const toggleTeamModel = () => {
    setTeamModel(!teamModel);
  };
  const closeTeamModal = () => {
    setTeamModel(false);
  };
  const selectTeamRow = (id, row) => {
    setTeamId(id);
    setTeamText(row.name);
    setTeamImage(row.image);
    closeTeamModal();
  };


  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">

        <Container>



      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Challenge Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">
              Challenge Management
              </li>
              :{" "}
              <li className="breadcrumb-item">
                <Link to="#">Challenge Update</Link>
              </li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history(-1)}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>
   

      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Challenge Update </h4>
                <Form onSubmit={updateNotificationData}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">

                        

                        <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Venue
                            </label>

                            <div
                              className="col-sm-6"
                              onClick={() => toggleModel()}
                            >
                              {venueText ? (
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#5e18c9",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleModel()}
                                  >
                                    Choose Venue
                                  </button>
                                  <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img
                                    src={venueImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readonly
                                    value={venueText}
                                    id="selectedVenue"
                                    placeholder="No venue chosen"
                                  />
                                </div>
                              ) : (
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#5e18c9",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleModel()}
                                  >
                                    Choose Venue
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    value={venueText}
                                    id="selectedVenue"
                                    placeholder="No venue chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                      
                       
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Activity
                            </label>

                            <div
                              className="col-sm-6"
                              onClick={() => toggleActivityModel()}
                            >
                              {activityText ? (   
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#5e18c9",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseOfferBtn"
                                    onclick={() => toggleActivityModel()}
                                  >
                                    Choose Activity
                                  </button>
                                  <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img
                                    src={activityImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readonly
                                    value={activityText}
                                    id="selectedOffer"
                                    placeholder="No offer chosen"
                                  />
                                </div>
                              ) : (                                
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#5e18c9",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseOfferBtn"
                                    onclick={() => toggleActivityModel()}
                                  >
                                    Choose Activity
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    value={activityText}
                                    id="selectedOffer"
                                    placeholder="No activity chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>   


                          <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Note
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="text"
                              onChange={(e) =>
                                updateValue("note", e.target.value)
                              }
                              defaultValue={notificationData?.note}
                              name="note"
                              className="form-control"
                              id="horizontal-firstname-input"
                            />
                          </div>
                        </div>


                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Address
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="text"
                              onChange={(e) =>
                                updateValue("address", e.target.value)
                              }
                              defaultValue={notificationData?.address}
                              name="address"
                              className="form-control"
                              id="horizontal-firstname-input"
                            />
                          </div>
                        </div>                   
                      


                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-email-input"
                          className="col-sm-3 col-form-label"
                        >
                          Select Location
                        </label>
                        <div className="col-sm-6">
                          <LocationPicker
                            containerElement={
                              <div style={{ height: "100%" }} />
                            }
                            mapElement={<div style={{ height: "600px" }} />}
                            zoom={14}
                            defaultPosition={currentLocation}
                            onChange={handleLocationChange}
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-email-input"
                          className="col-sm-3 col-form-label"
                        >
                          Latitude
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="number" // Change the type to "number"
                            step="any"
                            name="latitude"
                            min={0}
                            value={notificationData?.latitude}
                            onChange={(e) =>
                              updateLocation(e.target.value, "lat")
                            }
                            className="form-control"
                            id="horizontal-email-input"
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-email-input"
                          className="col-sm-3 col-form-label"
                        >
                          Longitude
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="number" // Change the type to "number"
                            step="any"
                            name="longitude"
                            min={0}
                            value={notificationData?.longitude}
                            onChange={(e) =>
                              updateLocation(e.target.value, "lng")
                            }
                            className="form-control"
                            id="horizontal-email-input"
                          />
                        </div>
                      </div>
                        
                       
                            <div className="row mb-4">
                              <label
                                htmlFor="horizontal-firstname-input"
                                className="col-sm-3 col-form-label"
                              >
                                Date
                              </label>
                              <div className="col-sm-6">
                                <input
                                  value={notificationData?.date}
                                  type="date"
                                  onChange={(e) =>
                                    updateValue("date", e.target.value)
                                  }
                                  name="date"
                                  className="form-control"
                                  id="horizontal-firstname-input"
                                />
                              </div>
                            </div>
                            <div className="row mb-4">
                              <label
                                htmlFor="horizontal-firstname-input"
                                className="col-sm-3 col-form-label"
                              >
                               Time
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="time"
                                  onChange={(e) =>
                                    updateValue("time", e.target.value)
                                  }
                                  value={notificationData?.time}
                                  name="time"
                                  className="form-control"
                                  id="horizontal-firstname-input"
                                />
                              </div>
                            </div> 

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                           Matchlevel
                          </label>
                          <div className="col-sm-6">
                            <select
                              onChange={(e) =>
                                updateValue("matchLevel", e.target.value)
                              }
                              value={notificationData?.matchLevel}
                              name="matchLevel"
                              className="form-select"
                            >
                              <option value="">Select</option>
                              <option value="friendly">Friendly</option>
                              <option value="competitive">Competitive</option>                              
                            </select>
                          </div>
                        </div>   


                         <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                          participant Type
                          </label>
                          <div className="col-sm-6">
                            <select
                              onChange={(e) =>
                                updateValue("participantType", e.target.value)
                              }
                              value={notificationData?.participantType}
                              name="participantType"
                              className="form-select"
                            >
                              <option value="">Select</option>
                              <option value="team">Team</option>
                              <option value="individual">Individual</option>                              
                            </select>
                          </div>
                        </div>                         


                          <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                           Type
                          </label>
                          <div className="col-sm-6">
                            <select
                              onChange={(e) =>
                                updateValue("type", e.target.value)
                              }
                              value={notificationData?.type}
                              name="type"
                              className="form-select"
                            >
                              <option value="">Select</option>
                              <option value="private">Private</option>
                              <option value="public">Public</option>                              
                            </select>
                          </div>
                        </div>


                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                           Is Location Booked
                          </label>
                          <div className="col-sm-6">
                            <select
                              onChange={(e) =>
                                updateValue("isLocationMarkBooked", e.target.value)
                              }
                              value={notificationData?.isLocationMarkBooked}
                              name="isLocationMarkBooked"
                              className="form-select"
                            >
                              <option value="">Select</option>
                              <option value="true">Yes</option>
                              <option value="false">No</option>                              
                            </select>
                          </div>
                        </div>


                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Amount
                          </label>
                          <div className="col-sm-6">
                            <input 
                              type="text"
                              onChange={(e) =>
                                updateValue("amount", e.target.value)
                              }
                              defaultValue={notificationData?.amount}
                              name="note"
                              className="form-control"
                              id="horizontal-firstname-input"
                            />
                          </div>
                        </div>  
                     
                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col lg={6} className="ms-lg-auto"></Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      </Container> 


        </div>
      {openLightbox && (
        <Lightbox
          mainSrc={previewImage}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
      
      <ActivityModal
        show={activityModel}
        closeModal={closeActivityModal}
        selectRow={selectActivityRow}
      />
      <TeamModal
        show={teamModel}
        closeModal={closeTeamModal}
        selectRow={selectTeamRow}
      />
         <VenueModal
        show={model}
        closeModal={closeModalVenue}
        selectRow={selectRow}
      />
      {/* <MultipleSelectUserList
        showUser={userModel}
        onSelectUser={onSelectedUser}
        closeUserModal={closeUserModal}
        selectUserRow={selectUserRow}
      /> */}
      <FileUpload message="File Uploading" status={startLoader} />
    </React.Fragment>
  );
}
