import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner,Container } from "reactstrap";

import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Lightbox from "react-image-lightbox";

import { postRequest, postRequestForm } from "../../components/Common/Utils.js";
import ActivityModal from "../../components/Modal/ActivityModal.js";
import MultipleActivitySelect from "../../components/Modal/MultipleActivitySelect.js";
import SelectedActivity from "./SelectedActivity.js";


const defaultPosition = {
  lat: 25.2048345,
  lng: 55.2708345,
};

export default function CreateActivityCommission() {
  const location = useLocation();
  const state = location.state?.row;
  const [notificationData, setNotificationData] = useState({
    note: "",
    address: "",
    latitude: "",
    longitude: "",
    date: "",
    time: "",
    matchLevel: "",
    participantType: "",
    type: "",
    commission: 0,
    isLocationMarkBooked: ""   
  });
  const [loading, setLoading] = useState(false);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectedTarget, setSelectedTarget] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [userModel, setUserModel] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(defaultPosition);
  const [venueList, setVenueList] = useState([]);
  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");

  const [offerList, setOfferList] = useState([]);
  const [activityId, setActivityId] = useState("");
  const [activityText, setActivityText] = useState("");
  const [activityImage, setActivityImage] = useState("");

  const [categoryList, setCategoryList] = useState([]);
  const [teamId, setTeamId] = useState("");
  const [teamText, setTeamText] = useState("");
  const [teamImage, setTeamImage] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);

  const [model, setModel] = useState(false);
  const [teamModel, setTeamModel] = useState(false);
  const [activityModel, setActivityModel] = useState(false);
  const [startLoader, setStartLoader] = useState(false);
  let history = useNavigate();


  const updateNotificationData = async (e) => {
    e.preventDefault();

 

    if(!selectedUserIds?.length){
      toast.error("Activity is required");
      return;
    }


    if (!notificationData.commission) {
      toast.error("Amount is required");
      return;
    }

   
    
    const dataObj = {
      ...notificationData,    
      activityIds: selectedUserIds  
    };
   

   
    const response = await postRequest("activity/commission/create", dataObj);
    setLoading(false);
    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history(-1);
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...notificationData };
    list[index] = value;
    setNotificationData(list);
  };

  const handleLocationChange = ({ position, address, places }) => {
    const lng = position.lng;
    const lat = position.lat;
    const venueDt = { ...notificationData, latitude: lat, longitude: lng };
    setNotificationData(venueDt);
  }; 

  const toggleModel = () => {
    setModel(!model);
  };
  const closeModal = () => {
    setModel(false);
  };

  const selectRow = (id, name, venue) => {   
    setVenueId(id);
    setVenueText(name);
    setVenueImage(venue?.logo);   
    closeModalVenue();
  };

  const closeModalVenue = () => {
    setModel(false);
  };

  const toggleActivityModel = () => {
    setActivityModel(!activityModel);
  };
  const closeActivityModal = () => {
    setActivityModel(false);
  };

  const selectActivityRow = (id, row) => {
     setActivityId(id);
    setActivityText(row.title);
     setActivityImage(row.icon);
    closeActivityModal();
  };

  const toggleTeamModel = () => {
    setTeamModel(!teamModel);
  };
  const closeTeamModal = () => {
    setTeamModel(false);
  };
  const selectTeamRow = (id, row) => {
    setTeamId(id);
    setTeamText(row.name);
    setTeamImage(row.image);
    closeTeamModal();
  };

  const popSelectedUser = (id) => {
    const newList = selectedUserIds.filter((item) => item._id !== id);
    setSelectedUserIds(newList);
  };

  const onSelectedUser = (selectedList) => {
    setSelectedUserIds(selectedList);
  };


  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">

        <Container>



      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Activity Commission Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">
              Activity Commission
              </li>
              :{" "}
              <li className="breadcrumb-item">
                <Link to="#">Activity Commission Create</Link>
              </li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history(-1)}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>
   

      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Activity Commission Create </h4>
                <Form onSubmit={updateNotificationData}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">

                        

                 
                      
                       
                          {/* <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Activity
                            </label>

                            <div
                              className="col-sm-6"
                              onClick={() => toggleActivityModel()}
                            >
                              {activityText ? (   
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#5e18c9",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseOfferBtn"
                                    onclick={() => toggleActivityModel()}
                                  >
                                    Choose Activity
                                  </button>
                                  <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img
                                    src={activityImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readonly
                                    value={activityText}
                                    id="selectedOffer"
                                    placeholder="No offer chosen"
                                  />
                                </div>
                              ) : (                                
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#5e18c9",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseOfferBtn"
                                    onclick={() => toggleActivityModel()}
                                  >
                                    Choose Activity
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    value={activityText}
                                    id="selectedOffer"
                                    placeholder="No activity chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>    */}

                           <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Activity
                            </label>
                            <div
                              className="col-sm-6"
                              onClick={() => toggleActivityModel()}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  border: "1px solid #ced4da",
                                  padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                                  borderRadius: "0.25rem",
                                  lineHeight: 1.5,
                                }}
                              >
                                <div style={{ minWidth: "100%" }}>
                                  <a
                                    href="javascript:void(0)"
                                    style={{
                                      textDecoration: "none",
                                      color: "#5b626b",
                                      fontSize: "0.8125rem",
                                      fontWeight: 400,
                                    }}
                                  >
                                    Select Activity
                                  </a>
                                </div>
                                <div style={{ minWidth: "100%" }}>
                                  <a
                                    href="javascript:void(0)"
                                    style={{
                                      textDecoration: "none",
                                      color: "#5b626b",
                                      fontSize: "0.8125rem",
                                      fontWeight: 400,
                                    }}
                                  >
                                    <i
                                      style={{ color: "#5b626b" }}
                                      class="fa fa-plus"
                                    ></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/* <SelectUserList
                              users={userList}
                              onSelect={msUpdateTarget}
                              selectedTarget={selectedTarget}
                              setSelectedTarget={setSelectedTarget}
                              placeholder="Select Target"
                            /> */}
                          </div>

                          {selectedUserIds?.length > 0 && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Selected Activities
                            </label>
                            <div className="col-sm-6">
                              <SelectedActivity
                                selectedUser={selectedUserIds}
                                deleteHandler={popSelectedUser}
                              />
                            </div>
                          </div>
                        )}




                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Commission(%) 
                          </label>
                          <div className="col-sm-6">
                            <input 
                              type="number"
                              onChange={(e) =>
                                updateValue("commission", e.target.value)
                              }
                              defaultValue={notificationData?.amount}
                              name="note"
                              className="form-control"
                              id="horizontal-firstname-input"
                              min="0"    // Set to "1" if you want to restrict to 1-100
                              max="100"
                              step="1"
                            />
                          </div>
                        </div>  
                     
                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col lg={6} className="ms-lg-auto"></Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      </Container> 


        </div>
      {openLightbox && (
        <Lightbox
          mainSrc={previewImage}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
      
   
     
     <MultipleActivitySelect
        showUser={activityModel}
        onSelectUser={onSelectedUser}
        closeUserModal={closeActivityModal}
        selectUserRow={selectActivityRow}
      />
      {/* <FileUpload message="File Uploading" status={startLoader} /> */}
    </React.Fragment>
  );
}
