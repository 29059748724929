 
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import Lightbox from "react-image-lightbox";
import Toggle from "react-toggle";
import Tippy from "@tippy.js/react";
import "tippy.js/dist/tippy.css";

import "react-toggle/style.css";

import FilterModal from "../../components/Modal/FilterModal";

import {
  postRequest,
  deleteRequest,
  putRequest,
  deleteConfirmationWords,
  convertTimeToFormattedString,
} from "../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

import { Row, Col, Card, CardBody, Spinner, Button,Container } from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const filterObj = {
  activityLabels: "",
 commission: "",
  createdAt: "",
};

const ComissionList = (props) => {  
  const navigate = useNavigate();
  const [resetSearch, setResetSearch] = useState(false);
  const [featuredata, setfeaturedata] = useState([]);
  const [filterActive, setFilterActive] = useState(false);
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [sort, setsort] = React.useState("createdAt");
  const [selectedItems, setselectedItems] = useState([]);
  const [sortOrder, setsortOrder] = React.useState("desc");
  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [filter, setFilter] = useState({ ...filterObj });
  const [model, setModel] = useState(false);

  useEffect(() => {
    activityManagment();
  }, [currentPage]);

  const activityManagmentSearch = async () => {
    if (currentPage == 1) {
      activityManagment();
      return;
    }
    setCurrentPage(1);
  };

  useEffect(() => {
    if (currentPage == 1) {
      activityManagment();
      return;
    }
    setCurrentPage(1);
  }, [sizePerPageOrg, sort, sortOrder]);

  const GetValueOnChange = async (value, _id) => {
    let actId = _id;
    let status = value;   

    const res = await putRequest("activity/update", {
      activityId: actId,
      status: status === 'inactive' ? 'active' : 'inactive',
    });

    if (res.status == 1) {
      toast.success("Activity Status updated Successfully");
      activityManagment();
    } else {
      toast.error("Something went wrong");
      setLoader(false);
    }
  };
  const closeModal = () => {
    setModel(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);
      }
    }
  };

  const updateFilter = (key, value) => {
    const filterObj = { ...filter };
    filterObj[key] = value;
    setFilter(filterObj);
  };
  const fetchFilter = () => {
    const filterArgs = [];

    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        var filterObject = {
          [key]: filter[key]?.trim(),
        };

        if (key === "activityLabels") {
          filterObject.type = "regex";
        } else if (key === "commission") {
          filterObject.type = "eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });
    return filterArgs;
  };

  const applyFilter = () => {
    setFilterActive(true);
    const filterArgs = [];

    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        var filterObject = {
          [key]: filter[key]?.trim(),
        };

        if (key === "activityLabels") {
          filterObject.type = "regex";
        } else if (key === "status") {
          filterObject.type = "eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });
    toggleModel();
    activityManagment(filterArgs);
  };

  const resetFilter = () => {
    setFilterActive(false);
    setFilter({ ...filterObj });
    toggleModel();
    activityManagment(null, true);
  };

  function activeFormatter(cellContent, row) {
    const toggleStatus = () => {
      const newStatus = row.status == 'inactive' ? 'inactive' : 'active'; // Invert the status

      console.log("newStatus", newStatus);

      GetValueOnChange(newStatus, row?._id);
    };

    return (
      <label>
        <Toggle
          defaultChecked={row.status !== 'inactive'} // Check if status is 0 for "Active"
          onChange={toggleStatus}
          icons={false} // Hide the default icons
        /><br></br>
        {row.status === 'inactive'  ? "Unpublished" : "Published"}
      </label>
    );
  }
  function dateFormatter(cellContent, row) {
    return <div>{convertTimeToFormattedString(row.createdAt)}</div>;
  }
  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }

  const components = [
    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Activities</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.activityLabels}
          onChange={(e) => updateFilter("activityLabels", e.target.value)}
          name="activityLabels"
          className="form-control"
        />
      </div>
    </div>,
     <div className="row mb-4" key="title">
     <label className="col-sm-3 col-form-label">Commission</label>
     <div className="col-sm-6">
       <input
         type="text"
         value={filter.commission}
         onChange={(e) => updateFilter("commission", e.target.value)}
         name="commission"
         className="form-control"
       />
     </div>
   </div>,

   

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Created Date</label>
      <div className="col-sm-6">
        <input
          type="date"
          value={filter.createdAt}
          onChange={(e) => updateFilter("createdAt", e.target.value)}
          name="createdAt"
          className="form-control"
        />
      </div>
    </div>,

    <div className="mt-5 mt-lg-4" key="submit">
      <div className="row justify-content-end">
        <div className="col-sm-9">
          <div>
            <button
              type="button"
              onClick={applyFilter}
              className="btn btn-primary w-md"
            >
              Filter
            </button>
            <button
              onClick={resetFilter}
              type="button"
              className="btn btn-danger w-md mx-4"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>,
  ];


  
  function ImgFormatter(cellContent, row) {
    return (
      <>
        {row.icon ? (
          <img
            src={row.icon}
            onClick={() => showLightBox(row.icon)}
            alt="img"
            width={"50px"}
          />
        ) : (
          "No Image"
        )}
      </>
    );
  }

  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },
    {
      dataField: "activityLabels",
      text: "Activities",
      sort: false,
    },
 
    {
      dataField: "commission",
      text: "Commission (%)",
      sort: false,
    },
   

    {
      dataField: "createdAt",
      text: "Created At",
      formatter: dateFormatter,
      sort: false,
    },

    {
      dataField: "actions",
      text: "Actions",
      formatter: iconFormatter,
      sort: false,
    },
  ];
  const handleChange = (event) => {
    setSizePerPage(event.target.value);
  };
  const handlesorting = (event) => {
    setsort(event.target.value);
  };
  const handlesortOrder = (event) => {
    setsortOrder(event.target.value);
  };
  const handleReload = () => {
    setResetSearch(true);
    setSearchText("");    
  };

  useEffect(() => {  
    if(!resetSearch) return;
    if (currentPage == 1) {
      activityManagment([], true);
      return;
    }
    setCurrentPage(1);    
  }, [resetSearch]);

  const handleNavigate = row => {
    navigate(`/update-commission/${row._id}`, { state: { row } });
  };

  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div >
          <Tippy content="Edit">
            <button type="button" className=" btn btn-info  mx-2" onClick={() => handleNavigate(row)} >
             
              <i class="fal fa-pencil fs-5 text-light"></i>
            </button>
          </Tippy>
          <Tippy content="Delete">
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => handlerdelete(row._id)}
            >
              <i class="fal fa-trash  fs-5"></i>
            </button>
          </Tippy>
          &nbsp;&nbsp;
        </div>
      </React.Fragment>
    );
  }

  const activityManagment = async (filterArgs = [], resetFilter = false) => {
    // Clear the selected items when reloading the table
    setselectedItems([]);

    setLoader(true);
    const payload = {
      limit: sizePerPageOrg,
      page: currentPage,
      sortField: sort,
      sortOrder: sortOrder,
    };


    if (filterArgs?.length) {
      payload.filterArgs = filterArgs;
    } else {
      if (searchText && resetSearch === false) {
        payload.search = searchText.trim();
      }
    }

    if (filterActive && !filterArgs?.length && !resetFilter) {
      payload.filterArgs = fetchFilter();
    }

    try {
      const response = await postRequest(`activity/commission/list/all`, payload);
      const data = response.data;

      if(resetSearch){
        setResetSearch(false);
      }
      setTotalPage(data.count);
      setCurrentFPage(data.page);
      setfeaturedata(data.list);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }
  };

  const handlerdelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this activity",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        showRandomWordDialog(id);
      }
    });
  };

  const showRandomWordDialog = (id) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteFeature(id);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteFeature = async (id) => {
    const response = await deleteRequest("activity/commission/delete", {
      id: id,
    });
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      activityManagment();
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  const handlerdeleteMultiple = (ids) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        showRandomWordDialogM(ids);
      }
    });
  };

  const showRandomWordDialogM = (ids) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteMultipleFunction(ids);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteMultipleFunction = async (ids) => {
    const response = await deleteRequest("activity/commission/delete", { ids: ids });
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      activityManagment();
      setselectedItems([]);
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    style: { background: "#c8e6c9" },
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setselectedItems([...selectedItems, row._id]);
      } else {
        setselectedItems(selectedItems.filter((x) => x !== row._id));
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (!isSelect) {
        setselectedItems([]);
        return;
      }

      const ids = rows.map((r) => r._id);
      setselectedItems(ids);
    },
  };
  const rowClasses = (row, rowIndex) => {
    return selectedItems.includes(row._id) ? "selected-row-class" : "";
  };
  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <div className="page-content">

        <Container fluid>
        <Row>
          <Col className="12">
            <div className="page-title-box">
              <h4>Activity Commission Management</h4>

              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">Activity Commission Management</li>:{" "}
                <li className="breadcrumb-item">Activity Commission List</li>
              </ol>
            </div>
          </Col>
          <div className="col-md-2 text-end mb-3">
            <Link to="/create-commission">
              {" "}
              <Button>
                {" "}
                <i class="fas fa-plus"></i> Create
              </Button>{" "}
            </Link>
            &nbsp;&nbsp;&nbsp;
          </div>
          <Col className="col-12">
            <Card>
              {loader ? (
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              ) : (
                <CardBody className="table-shorting">
                  {
                    <PaginationProvider
                      pagination={paginationFactory({
                        page: currentFPage,
                        sizePerPage: sizePerPageOrg,
                        totalSize: totalPage,
                        custom: true,
                      })}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <>
                          <ToolkitProvider
                            keyField="_id"
                            data={featuredata}
                            columns={columns}
                          >
                            {(props) => (
                              <div className='row px-3 g-3'>
                                <div className="col-6 col-md-2 d-flex align-items-center">
                                  <label className="mb-0 text-label me-2">
                                    Page Size
                                  </label>

                                  <select
                                    value={sizePerPageOrg}
                                    onChange={handleChange}
                                    className="form-select"                                    
                                  >
                                    <option value="10">10</option>

                                    <option value="20">20</option>

                                    <option value="50">50</option>
                                  </select>
                                  </div>
                                  <div className="col-6 col-md-2 d-flex align-items-center">
                                  <label className="mb-0 text-label me-2">
                                    Sort By:
                                  </label>
                                  <select
                                    value={sort}
                                    onChange={handlesorting}
                                    className="form-select"                                    
                                  >
                                    <option value="commission">Commission</option>
                                    <option value="createdAt">
                                      Created At
                                    </option>
                                  </select>
                                  </div>
                                  <div className="col-12 col-md-2 d-flex align-items-center">
                                  <label className="mb-0 text-label ms-2">
                                    Sort Order:
                                  </label>
                                  <select
                                    value={sortOrder}
                                    onChange={handlesortOrder}
                                    className="form-select"                                  
                                  >
                                    <option value="asc"> Ascending</option>
                                    <option value="desc">Descending</option>
                                  </select>
                                  </div>

                                   <div className="col-12  offset-md-1 col-md-3  d-flex align-items-center  justify-content-start justify-content-md-end">
                                  
                                    <input
                                      type="search"
                                      placeholder="Search"
                                      value={searchText}
                                      class="form-control me-2"
                                      onChange={(e) =>
                                        setSearchText(e.target.value)
                                      }
                                    
                                    />
                                    <button
                                      onClick={() => {
                                        activityManagmentSearch();
                                      }}
                                      type="button"
                                      className="btn btn-primary me-2"
                                    >
                                      Search
                                    </button>
                                    </div>
                                    <div className="col-12 flex-wrap col-md-2 d-flex align-items-center">

                                    {selectedItems.length > 0 && (
                                      <button
                                        className="btn btn-danger me-2"
                                        onClick={() => {
                                          handlerdeleteMultiple(selectedItems);
                                        }}
                                      >
                                        Delete Selected
                                      </button>
                                    )}
                                    <button
                                      type="button"
                                      onClick={handleReload}
                                      className="btn btn-danger me-2"
                                    >
                                      Reset
                                    </button>

                                    <button
                                      type="button"
                                      onClick={toggleModel}
                                      className="btn btn-info"
                                    >
                                      Filter
                                    </button>
                                  </div>
                               

                                <Col className="col-lg-12 mt-3 text-end">
                                  <BootstrapTable
                                    onTableChange={handleTableChange}
                                    noDataIndication={() => (
                                      <div>No data available</div>
                                    )}
                                    remote={true}
                                    selectRow={selectRow}
                                    rowClasses={rowClasses}
                                    // classes="table-custom-striped"
                                    classes="table-custom-border"
                                    {...props.baseProps}
                                    {...paginationTableProps}
                                  />
                                </Col>
                                </div>
                             
                            )}
                          </ToolkitProvider>

                          <Row>
                            <Col className="col-lg-12 text-end">
                              <div className="text-md-right">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </PaginationProvider>
                  }
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
        </Container>
        </div>
        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
      </React.Fragment>
      <FilterModal
        show={model}
        closeModal={closeModal}
        components={components}
      />
    </>
  );
};

export default ComissionList;
