import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Spinner,Container } from "reactstrap";
import CustomAvatar from "../../components/Common/Avatar.js";
import {
  DeleteConfirmDialog,
  deleteRequest,
  getRequest,
  postRequest,
  postRequestForm,
  putRequest,
} from "../../components/Common/Utils";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

 import "./CustomCarduser.css";
 import "./CustomCardAct.css";

import { DetailsOutlined, Visibility } from "@material-ui/icons";
import TeamListOneTable from "./TeamOnelistTable";
import TeamListTwoTable from "./TeamTwolistTable";



export default function Pickupdetails() {  
  const [pickupData, setData] = useState([]);  
  const [loader, setLoader] = useState(true);
  const [openLightbox, setOpenLightBox] = React.useState(false);  


  const useStyles = makeStyles({
    tableHeader: {
      color: "#ffffff", // Use your preferred color
      backgroundColor: "#5e18c9", // Header background color
      fontWeight: "bold",
    },
    descriptionText: {
      fontSize: "0.8rem",
    },
    deleteIcon: {
      color: "red",
    },
    detailIcon: {
      color: "blue",
    },
    roundImage: {
      borderRadius: "50%",
      width: "100px",
      height: "100px",
    },
    roundImageSm: {
      borderRadius: "50%",
    },
  });

  const classes = useStyles();

  const { id } = useParams();


  const PickupFetchDetail = async () => {
    setLoader(false);
    postRequest(`activity/challenge/ids`, { ids: [id] }).then((data) => {
      let pickupdata = data.data[0];
      let status = data.status;


      setData(pickupdata);

      if (status == 1) {
        setLoader(false);
      }
      setLoader(false);
    });
  };
  useEffect(() => {
    PickupFetchDetail();
  }, []);

  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

 
  const {
    name,
    activityName,
    note,
    address,
    amount,
    matchLevel,
    type,
    activity,
    date,
    time,   
    createdAt,
  
  } = pickupData || {};

  console.log(pickupData)

  const initials =
    pickupData?.first_name?.charAt(0).toUpperCase() +
    pickupData?.last_name?.charAt(0).toUpperCase();
  return (
    <>
      <React.Fragment>
      <div className="page-content">

      <Container fluid> 
        <Row>
          <Col className="10">
            <div className="page-title-box">
              <h4>Challenge Management</h4>

              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">Challenge Management</li>:
                <li className="breadcrumb-item">Challenge details</li>
              </ol>
            </div>
          </Col>
          <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.back()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>

          {loader ? (
            <CardBody style={{ height: "100px" }}>
              <Spinner
                color="info"
                style={{
                  height: "4rem",
                  width: "4rem",
                  position: "absolute",
                  left: "50%",
                }}
              >
                Loading...
              </Spinner>
            </CardBody>
          ) : (
            <>
              <Col md="12">
                <Row>
                  <Card className="custom-card">
                    <div
                      className="cover-image"
                      style={{ backgroundColor: "#1d9bf0" }}
                    ></div>
                    <div className="profile-image">
                      {activity?.icon && (
                        <img
                          src={activity?.icon}
                          onClick={() => showLightBox(activity?.icon)}
                          alt="User Avatar"
                        />
                      ) }
                    </div>

                    <div className="row my-2 py-2" style={{padding: "16px"}}>
                      <div
                        className="userinfo col-md-12 "
                        style={{ paddingLeft: "20px" }}
                      >
                        <br></br><br></br>

                        <div className="fst">
                          <h3>  
                                                      
                            {activity?.title}
                          </h3>
                        </div>

                        <div className="row">
                          <div className="col-md-4 fst py-1">
                            <p>
                              Note: <span> {note}</span>
                            </p>
                          </div>
                          <div className="col-md-4 fst py-1">
                            <p>
                              Address: <span> {address}</span>
                            </p>
                          </div>
                          <div className="col-md-4 fst py-1">
                            <p>
                              Type: <span> {type}</span>
                            </p>
                          </div>
                          <div className="col-md-4 fst py-1">
                            <p>
                              Date: <span> {date}</span>
                            </p>
                          </div>
                          <div className="col-md-4 fst py-1">
                            <p>
                              Time: <span> {time}</span>
                            </p>
                          </div>
                          <div className="col-md-4 fst py-1">
                            <p>
                              Match Level: <span> {matchLevel}</span>
                            </p>
                          </div>
                          <div className="col-md-4 fst py-1">
                            <p>
                              Amount: <span> {amount}</span>
                            </p>
                          </div>
                     
                          <div className="col-md-4 fst py-1">
                            <p>
                              Created At: <span> {formatDate(createdAt)}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Row>
              </Col>
              <Col md="12" style={{marginLeft:"inherit"}}>
                <div
                  id="exTab2"
                  className="twitter-like-tabs2"
                  style={{ background: "#ffff", marginLeft: "25px" }}
                >
                  <ul
                    className="nav nav-pills"
                    style={{ width: "480px", display: "contents" }}
                  >
                    <li className="active">
                      <a href="#teamOne" data-toggle="tab">                        
                        { pickupData?.participantType === 'team' ? 'Team A Members' : 'Player 1' }
                      </a>
                    </li>

                    <li>
                      <a href="#teamTwo" data-toggle="tab">                       
                        { pickupData?.participantType === 'team' ? 'Team B Members' : 'Player 2' }
                      </a>
                    </li>
                  
              
                  </ul>
                </div>
              </Col>

                      
              <Col md="12">
                <div className="tab-content clearfix">
                <div className="tab-pane active padded-tab" id="teamOne">
                    {pickupData?.length !== 0 && (
                      <TeamListOneTable
                        pickupData={pickupData?.members}
                        memberUserId={pickupData?.participantA}
                      />
                    )}
                  </div>

                  <div className="tab-pane padded-tab" id="teamTwo">
                    {pickupData?.length !== 0 && (
                      <TeamListTwoTable
                        pickupData={pickupData?.pickups}
                        pickupUserId={pickupData?.participantB || "INVALIDID"}
                      />
                    )}
                  </div>
                  
                </div>
              </Col>
            </>
          )}
        </Row>
      </Container>
      </div>
        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
      </React.Fragment>
      
    </>
  );
}
