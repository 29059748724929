import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner, Label,Container } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Lightbox from "react-image-lightbox";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";

import {
  postRequest,
  postRequestForm,
  putRequest,
  putRequestForm,
} from "../../components/Common/Utils.js";
import MultipleActivitySelect from "../../components/Modal/MultipleActivitySelect.js";
import SelectedActivity from "./SelectedActivity.js";
import { ToastContainer, toast } from "react-toastify";
import SelectUserList from "../../components/Common/SelectUserList.js";


export default function UpdateActivityCommission() {
  const nav = useNavigate();  
  const location = useLocation();
  const state = location.state?.row;
  const [notificationData, setNotificationData] = useState(state);
  const [loading, setLoading] = useState(false);
  console.log("state", state);
  const [selectedUserIds, setSelectedUserIds] = useState(state.activities|| []);

  const [userModel, setUserModel] = useState(false);
  const [userList, setUserList] = useState([]);
  const [selectedTarget, setSelectedTarget] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [openLightbox2, setOpenLightBox2] = React.useState(false);
  const [imageIcon, setImageIcon] = useState("");
  // let history = useNavigate();

  const msUpdateTarget = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedTarget(ids);
  };

  const [venueList, setVenueList] = useState([]);
  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");

  const [offerList, setOfferList] = useState([]);
  const [offerId, setOfferId] = useState("");
  const [offerText, setOfferText] = useState("");
  const [offerImage, setOfferImage] = useState("");

  const [categoryList, setCategoryList] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [categoryText, setCategoryText] = useState();
  const [categoryImage, setCategoryImage] = useState("");
  const [activityId, setActivityId] = useState("");
  const [activityText, setActivityText] = useState("");
  const [activityImage, setActivityImage] = useState("");

  const [model, setModel] = useState(false);
  const [categoryModel, setCategoryModel] = useState(false);
  const [offerModel, setOfferModel] = useState(false);
  const [activityModel, setActivityModel] = useState(false);
  const [startLoader, setStartLoader] = useState(false);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  useEffect(() => {
    // Extract date and time from scheduledDateTime
    const defaultDate = notificationData?.scheduledDateTime
      ? new Date(notificationData.scheduledDateTime).toISOString().split("T")[0]
      : "";
    const defaultTime = notificationData?.scheduledDateTime
      ? new Date(notificationData.scheduledDateTime)
          .toISOString()
          .split("T")[1]
          .slice(0, 5)
      : "";

    setDate(defaultDate);
    setTime(defaultTime);
  }, [notificationData]);



 

  const fetchState = async () => {
    const data = { ...state };
    setNotificationData(data);
    setLoading(false);
    setVenueImage(data?.venue?.logo);
    setOfferImage(data?.offer?.image);
    setCategoryImage(data?.category?.image);
    setSelectedUserIds(data?.users ? data?.users : []);
  };
  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setNotificationData({ ...notificationData, image: reader.result });
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);
  //   let search = state._id;
  //   let finalsearch = search.toString();

  

  const updateNotificationData = async (e) => {
    e.preventDefault();

    
    if(!selectedUserIds?.length){
      toast.error("Activity is required");
      return;
    }


    if (!notificationData.commission) {
      toast.error("Amount is required");
      return;
    }

   
    
    
    
    
   

    const dataObj = {
      id: notificationData._id,
      activityIds: selectedUserIds,  
      commission: notificationData.commission,

    };
   
   
    setLoading(true);
    const response = await putRequest("activity/commission/update", dataObj);
    setLoading(false);

    

    if (response.status == 1) {
      toast.success(response.message);
      // redirect to list page
      setTimeout(() => {
        nav(-1);
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...notificationData };
    list[index] = value;
    setNotificationData(list);
  };

  

  const fetchUsers = async () => {
    const param = { page: 1, limit: 100000 };
    const user = await postRequest("user/list/admin", param);
    if (user.status == 1) {
      // const selected = user.data.list.map((item) => {

      // });
      // setSelectedTarget(selected);
      const options = user.data.list.map((item) => {
        return {
          id: item._id,
          phone: item.phone,
          name: `${item.first_name} ${item.last_name}`,
          email: item.email,
        };
      });
      setUserList(options);
    }
  };

  useEffect(() => {
    setImageIcon(state?.image ? state?.image : "");
  }, [state]);

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
      setImageIcon("");
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    fetchUsers();
    setCategoryId(state.typeId);
    setVenueId(state.typeId);
    setOfferId(state.typeId);
    if (state.type === "venue") fetchVenueList();
    if (state.type === "offer") fetchOfferList();
    if (state.type === "category") fetchCategoryList();
  }, []);

  const fetchVenueList = async () => {
    setLoading(true);
    const response = await postRequest("venue/list", { limit: 10000000 });
    if (response.status == 1) {
      setVenueList(response.data.list);
      setVenueText(
        response.data.list?.find((x) => x._id === state.typeId)?.name
      );
    }
    setLoading(false);
  };

  const fetchOfferList = async () => {
    setLoading(true);
    const response = await postRequest("venue/offer/list/admin", {
      limit: 10000000,
    });
    if (response.status == 1) {
      setOfferList(response.data.list);
      setOfferText(
        response.data.list?.find((x) => x._id === state.typeId)?.title
      );
    }
    setLoading(false);
  };

  const fetchCategoryList = async () => {
    setLoading(true);
    const response = await postRequest("venue/category/list/admin", {
      limit: 10000000,
    });
    if (response.status == 1) {
      setCategoryList(response.data.list);
      setCategoryText(
        response.data.list?.find((x) => x._id === state.typeId)?.title
      );
    }
    setLoading(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };
  const closeModal = () => {
    setModel(false);
  };
  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };
  const showLightBox2 = (imageIcon) => {
    setImageIcon(imageIcon);
    setOpenLightBox2(true);
  };
  const selectRow = (id, name, row) => {
    setVenueId(id);
    setVenueText(name);
    setVenueImage(row.logo);
    closeModal();
  };

  const toggleOfferModel = () => {
    setOfferModel(!offerModel);
  };
  const closeOfferModal = () => {
    setOfferModel(false);
  };
  const selectOfferRow = (id, name, row) => {
    setOfferId(id);
    setOfferText(name);
    setOfferImage(row.image);
    closeOfferModal();
  };

  const toggleActivityModel = () => {
    setActivityModel(!activityModel);
  };
  const closeActivityModal = () => {
    setActivityModel(false);
  };

  const selectActivityRow = (id, row) => {
     setActivityId(id);
    setActivityText(row.title);
     setActivityImage(row.icon);
    closeActivityModal();
  };


  const toggleCategoryModel = () => {
    setCategoryModel(!categoryModel);
  };
  const closeCategoryModal = () => {
    setCategoryModel(false);
  };
  const selectCategoryRow = (id, name, row) => {
    setCategoryId(id);
    setCategoryText(name);
    setCategoryImage(row.image);
    closeCategoryModal();
  };

  const popSelectedUser = (id) => {
    const newList = selectedUserIds.filter((item) => item._id !== id);
    setSelectedUserIds(newList);
  };

  const onSelectedUser = (selectedList) => {
    setSelectedUserIds(selectedList);
  };
  const closeUserModal = () => {
    setUserModel(false);
  };

  const selectUserRow = (id, name) => {
    setUserId(id);
    setUserText(name);
    closeUserModal();
  };

  const toggleUserModel = () => {
    setUserModel(!userModel);
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">

        <Container>


      <Row>
        <Col className="12">
          <div className="page-title-box">
            <h4>Activity Commission Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Activity Commission Management</li>:{" "}
              <li className="breadcrumb-item">Activity Commission update</li>
            </ol>
          </div>
        </Col>
        <div className="col-md-2 text-end">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={() => history.back()}
          >
            <i className="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </Row>

    

      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Activity Commission Update </h4>
                <Form onSubmit={updateNotificationData}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">                      
                       
                    

                        <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Activity
                            </label>
                            <div
                              className="col-sm-6"
                              onClick={() => toggleActivityModel()}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  border: "1px solid #ced4da",
                                  padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                                  borderRadius: "0.25rem",
                                  lineHeight: 1.5,
                                }}
                              >
                                <div style={{ minWidth: "100%" }}>
                                  <a
                                    href="javascript:void(0)"
                                    style={{
                                      textDecoration: "none",
                                      color: "#5b626b",
                                      fontSize: "0.8125rem",
                                      fontWeight: 400,
                                    }}
                                  >
                                    Select Activity
                                  </a>
                                </div>
                                <div style={{ minWidth: "100%" }}>
                                  <a
                                    href="javascript:void(0)"
                                    style={{
                                      textDecoration: "none",
                                      color: "#5b626b",
                                      fontSize: "0.8125rem",
                                      fontWeight: 400,
                                    }}
                                  >
                                    <i
                                      style={{ color: "#5b626b" }}
                                      class="fa fa-plus"
                                    ></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/* <SelectUserList
                              users={userList}
                              onSelect={msUpdateTarget}
                              selectedTarget={selectedTarget}
                              setSelectedTarget={setSelectedTarget}
                              placeholder="Select Target"
                            /> */}
                          </div>

                        {selectedUserIds?.length > 0 && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                            Selected Activities
                            </label>
                            <div className="col-sm-6">
                              <SelectedActivity
                                selectedUser={selectedUserIds}
                                deleteHandler={popSelectedUser}
                              />
                            </div>
                          </div>
                        )}

                         <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Commission(%) 
                          </label>
                          <div className="col-sm-6">
                            <input 
                              type="number"
                              onChange={(e) =>
                                updateValue("commission", e.target.value)
                              }
                              defaultValue={notificationData?.commission}
                              name="note"
                              className="form-control"
                              id="horizontal-firstname-input"
                              min="0"    // Set to "1" if you want to restrict to 1-100
                              max="100"
                              step="1"
                            />
                          </div>
                        </div>  

                       
                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} className="ms-lg-auto"></Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      </Container> 


</div>

  

      <MultipleActivitySelect
        showUser={activityModel}
        onSelectUser={onSelectedUser}
        closeUserModal={closeActivityModal}
        selectUserRow={selectActivityRow}
      />
   
    </React.Fragment>
  );
}
