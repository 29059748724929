import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  highlighted: {
    backgroundColor: "#5E18C9 !important",
    color: "#fff !important",
  },
}));

/**
 * Check if an item is selected by comparing its _id
 */
function isSelected(item, selectedItemList) {
  return selectedItemList.some(
    (selectedItem) => selectedItem === item.title
  );
}

export default function TagSelection({
  itemList = [],              // Array of objects, e.g. [{ _id, title, ... }, ...]
  selectedItemList = [],      // Pre-selected items
  onSelectionChange,          // Callback for when selection updates
  addButton = null,
}) {
  const classes = useStyles();

  // Initialize selectedItems state from the passed 'selectedItemList'
  const [selectedItems, setSelectedItems] = useState([...selectedItemList]);

  /**
   * Handle clicking on a chip:
   * - If it's already selected, remove it
   * - Otherwise, add it
   */
  const handleClick = (item) => {
    let newSelectedItems;
    if (isSelected(item, selectedItems)) {
      // Remove item by _id
      newSelectedItems = selectedItems.filter(
        (selectedItem) => selectedItem !== item.title	
      );
    } else {
      // Add item
      newSelectedItems = [...selectedItems, item];
    }
    setSelectedItems(newSelectedItems);
    onSelectionChange?.(newSelectedItems);
  };

  /**
   * If 'selectedItems' changes internally, we notify parent as well
   */
  useEffect(() => {
    onSelectionChange?.(selectedItems);
  }, [selectedItems, onSelectionChange]);

  return (
    <div className={classes.root}>
      {itemList.map((item) => {
        const label = item.title || "No Title";
        return (
          <li key={item._id}>
            <Chip
              label={label}
              onClick={() => handleClick(item)}
              className={`${classes.chip} ${
                isSelected(item, selectedItems) ? classes.highlighted : ""
              }`}
            />
          </li>
        );
      })}

      {/* {addButton && (
        <button type="button" className="btn btn-primary mx-1">
          <Link
            to={{
              pathname: addButton.path,
              state: addButton.state,
            }}
          >
            <i className="fal fa-plus fs-5 text-light"></i>
          </Link>
        </button>
      )} */}
    </div>
  );
}
