import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
// import { withRouter } from "react-router-dom";
import { Link, NavLink} from "react-router-dom";

//i18n
import { withTranslation } from 'react-i18next';

import { connect } from "react-redux";
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader
} from "../../store/actions";
import withRouter from "../Common/withRouter";

class SidebarContent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pathName: this.props.router.location.pathname,
    };

  }

  componentDidMount() {
    this.initMenu();
  }

  UNSAFE_componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {

        if (this.props.type !== prevProps.type) {
            this.initMenu();
        }

    }
    if (this.props.router.location.pathname !== prevProps.router.location.pathname) {
      this.setState({ pathName: this.props.router.location.pathname }, () => {
        this.initMenu();
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");
    const { pathName } = this.state;


    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
           <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li className="menu-title">{this.props.t("Main")} </li> */}
            <li>
              <NavLink to="/dashboard" className="waves-effect">
                <i className="mdi mdi-view-dashboard"></i>
                {/* <span className="badge rounded-pill bg-primary float-end">
                  2
                </span> */}
                <span>{this.props.t("Dashboard")}</span>
              </NavLink>
            </li>

            <li>
              <NavLink to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-account"></i>
                <span>{this.props.t("Users")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
              <li>
                  <NavLink to="/users" className=" waves-effect">
                    <span>{this.props.t("User Management")}</span>
                  </NavLink>
                </li>

               

              </ul>
            </li>

            
       

            <li>
              <NavLink to="/#" className="has-arrow waves-effect">
              <i className="mdi mdi-walk"></i>
                <span>{this.props.t("Activity")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">               
                <li>
                  <NavLink to="/activity" className=" waves-effect">
                    <span>{this.props.t("Activity Management")}</span>
                  </NavLink>
                </li>      

                <li>
                  <NavLink to="/commission" className=" waves-effect">
                    <span>{this.props.t("Activity Commission")}</span>
                  </NavLink>
                </li>      
               
              </ul>
            </li>

           
            <li>
              <NavLink to="/#" className="has-arrow waves-effect">
              <i className="mdi mdi-star"></i>
                <span>{this.props.t("Feature")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">               
                <li>
                  <NavLink to="/feature" className=" waves-effect">
                    <span>{this.props.t("Feature Management")}</span>
                  </NavLink>
                </li>      
               
              </ul>
            </li>

            <li>
              <NavLink to="/#" className="has-arrow waves-effect">
              <i className="mdi mdi-tooltip-edit"></i>
                <span>{this.props.t("Voucher")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">               
                <li>
                  <NavLink to="/vouchers" className=" waves-effect">
                    <span>{this.props.t("Voucher Management")}</span>
                  </NavLink>
                </li>      
               
              </ul>
            </li>

            <li>
              <NavLink to="/#" className="has-arrow waves-effect">
              <i className="mdi mdi-home-modern"></i>
                <span>{this.props.t("Venue")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">               
                <li>
                  <NavLink to="/venue" className=" waves-effect">
                    <span>{this.props.t("Venue Management")}</span>
                  </NavLink>
                </li>      
               
              </ul>
            </li>

            <li>
            <NavLink to="/#" className="has-arrow waves-effect">
              <i className="mdi mdi-view-grid"></i> {/* Updated Icon */}
              <span>{this.props.t("Category")}</span>
            </NavLink>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <NavLink to="/category" className="waves-effect">
                  <span>{this.props.t("Category Management")}</span>
                </NavLink>
              </li>
            </ul>
          </li>


            <li>
              <NavLink to="/#" className="has-arrow waves-effect">
              <i className="mdi mdi-sale"></i>
                <span>{this.props.t("Offer")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">               
                <li>
                  <NavLink to="/offer" className=" waves-effect">
                    <span>{this.props.t("Offer Management")}</span>
                  </NavLink>
                </li>    

                <li>
                  <NavLink to="/orders" className=" waves-effect">
                    <span>{this.props.t("Order Management")}</span>
                  </NavLink>
                </li>  
               
              </ul>
            </li>
            <li>
              <NavLink to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-account"></i>
                <span>{this.props.t("Teams")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
              <li>
                  <NavLink to="/teams" className=" waves-effect">
                    <span>{this.props.t("Team Management")}</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/pickups" className=" waves-effect">
                    <span>{this.props.t("Challenge Management")}</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/admin-pickups" className=" waves-effect">
                    <span>{this.props.t("Admin's Challenge")}</span>
                  </NavLink>
                </li>


                <li>
                  <NavLink to="/challenges" className=" waves-effect">
                    <span>{this.props.t("Swap Management")}</span>
                  </NavLink>
                </li>
               

              </ul>
            </li>

           
            <li>
              <NavLink to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-tooltip-edit"></i>
                <span>{this.props.t("Banner")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                
                <li>
                  <NavLink to="/banner" className="waves-effect">
                    <span>{this.props.t("Banner Management")}</span>
                  </NavLink>
                </li>
              </ul>
            </li>

            <li>
              <NavLink to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-apps"></i>
                <span>{this.props.t("App")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink to="/pages" className=" waves-effect">
                    <span>{this.props.t("Setting Management")}</span>
                  </NavLink>
                </li>               
              </ul>
            </li>   
            <li>
              <NavLink to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-cube-outline"></i>
                <span>{this.props.t("Home Blocks")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink to="/homeblock" className=" waves-effect">
                    <span>{this.props.t("Home Block Management")}</span>
                  </NavLink>
                </li> 
                <li>
                  <NavLink to="/searchblock" className=" waves-effect">
                    <span>{this.props.t("Search Block Management")}</span>
                  </NavLink>
                </li> 
                <li>
                  <NavLink to="/blocksize" className=" waves-effect">
                    <span>{this.props.t("Size Management")}</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/videos" className=" waves-effect">
                    <span>{this.props.t("Video Management")}</span>
                  </NavLink>
                </li>
                 
                 <li>
                  <NavLink to="/stories" className=" waves-effect">
                    <span>{this.props.t("Story Management")}</span>
                  </NavLink>
                </li>
              </ul>
            </li>  

            <li>
              <NavLink to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-bell-ring"></i>
                <span>{this.props.t("Notification")}</span>
              </NavLink>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink to="/notification" className=" waves-effect">
                    <span>{this.props.t("Notification")}</span>
                  </NavLink>
                </li>
               
              </ul>
            </li>  
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
  return { ...state.Layout };
};

export default withRouter(connect(mapStatetoProps, {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeLayoutWidth,
  changePreloader
})(withTranslation()(SidebarContent)));
